var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"product-card"},[_c('div',{staticClass:"product-img"},[_c('router-link',{attrs:{"to":{
                    name: 'ProductDetailsPage',
                    params: {
                        id: _vm.product.id,
                    },
                },"target":"_blank"}},[_c('img',{attrs:{"src":_vm.product.image_url
                            ? _vm.product.image_url
                            : _vm.product.img_url
                            ? _vm.product.img_url
                            : require('@/assets/images/default-product-img.png'),"alt":""}})]),(_vm.product.bestseller)?_c('div',{staticClass:"best-seller-tag"},[_c('HighlightSVG',{attrs:{"highlight":"bestseller"}}),_vm._v(" Popular ")],1):_vm._e()],1),_c('div',[_c('div',{staticClass:"product-details"},[_c('router-link',{attrs:{"to":{
                        name: 'ProductDetailsPage',
                        params: {
                            id: _vm.product.id,
                        },
                    },"target":"_blank"}},[_c('p',{staticClass:"bold-font"},[_vm._v(" "+_vm._s(_vm.product.name.slice(0, 25))+" "),(_vm.product.name.length > 25)?_c('span',[_vm._v("...")]):_vm._e()])]),_c('p',[_vm._v(" "+_vm._s(_vm.product.vendor)+" ")]),_c('p',{staticClass:"bold-font"},[_vm._v(" ₹"+_vm._s(_vm.convertToIndianNumberSystem( _vm.product.item_price ? _vm.product.item_price : _vm.product.price ))+" ")]),(_vm.product.recommended)?_c('div',{staticClass:"recommended-tag"},[_c('HighlightSVG',{attrs:{"highlight":"recommended"}}),_vm._v(" Recommended by Siloho ")],1):_c('div',{staticClass:"no-tag"})],1)]),(_vm.product.available && !_vm.product.available)?_c('div',{staticClass:"product-unavailable"},[_vm._v(" Product Unavailable ")]):_vm._e()]),_c('div',{staticClass:"product-card-hover"},[_c('div',{staticClass:"product-img"},[_c('router-link',{attrs:{"to":{
                    name: 'ProductDetailsPage',
                    params: {
                        id: _vm.product.id,
                    },
                },"target":"_blank"}},[_c('img',{attrs:{"src":_vm.product.image_url
                            ? _vm.product.image_url
                            : _vm.product.img_url
                            ? _vm.product.img_url
                            : require('@/assets/images/default-product-img.png'),"alt":""}})]),(_vm.product.bestseller)?_c('div',{staticClass:"best-seller-tag"},[_c('HighlightSVG',{attrs:{"highlight":"bestseller"}}),_vm._v(" Popular ")],1):_vm._e()],1),_c('div',[_c('div',{staticClass:"product-details-hover"},[(_vm.product.available)?_c('div',{staticClass:"actions"},[(_vm.product.active_quantity === 0)?[_c('Button',{staticClass:"customisation-button",attrs:{"name":"ADD TO ROOM","normal":""},nativeOn:{"click":function($event){return _vm.checkDraft({
                                    product: _vm.product,
                                    quantity: _vm.product.total_quantity,
                                    scene_id: _vm.scene_id,
                                    index: _vm.index,
                                    operation: 'restore',
                                    ehd_id: _vm.ehd_id,
                                })}}})]:[(_vm.product.available === true)?_c('div',{staticClass:"number-of-products"},[(_vm.product.active_quantity !== 0)?_c('div',{staticClass:"remove-one",on:{"click":function($event){return _vm.checkDraft({
                                        product: _vm.product,
                                        scene_id: _vm.scene_id,
                                        operation: 'count',
                                        enable: false,
                                        ehd_id: _vm.ehd_id,
                                    })}}},[_c('OperationsSVG',{attrs:{"operation":"minus"}})],1):_c('div',{staticClass:"disable-icon"},[_c('OperationsSVG',{attrs:{"operation":"minus"}})],1),_c('div',{staticClass:"total"},[_vm._v(" "+_vm._s(_vm.product.active_quantity)+" ")]),_c('div',{staticClass:"add-one",on:{"click":function($event){return _vm.checkDraft({
                                        product: _vm.product,
                                        scene_id: _vm.scene_id,
                                        operation: 'count',
                                        enable: true,
                                        ehd_id: _vm.ehd_id,
                                    })}}},[_c('OperationsSVG',{attrs:{"operation":"plus"}})],1)]):_vm._e(),_c('Button',{staticClass:"customisation-button",attrs:{"name":"CHANGE","normal":""},nativeOn:{"click":function($event){return _vm.checkDraft({
                                    product: _vm.product,
                                    quantity: _vm.product.total_quantity,
                                    scene_id: _vm.scene_id,
                                    index: _vm.index,
                                    operation: 'change',
                                    ehd_id: _vm.ehd_id,
                                })}}})]],2):_vm._e(),_c('div',{staticClass:"price-remove"},[_c('p',{staticClass:"product-price",class:_vm.requiredDomain == false ? 'blur' : ''},[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( _vm.product.item_price ? _vm.product.item_price : _vm.product.price ))+" ")]),(_vm.product.active_quantity !== 0)?_c('div',{staticClass:"action-remove",on:{"click":function($event){return _vm.checkDraft({
                                product: _vm.product,
                                scene_id: _vm.scene_id,
                                operation: _vm.product.category_group
                                    ? 'toggle'
                                    : 'customProduct',
                                enable: false,
                                ehd_id: _vm.ehd_id,
                            })}}},[_c('OperationsSVG',{attrs:{"operation":"remove"}})],1):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }