var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"single-product"},[(_vm.product.bestseller)?_c('div',{staticClass:"best-seller-tag"},[_c('HighlightSVG',{attrs:{"highlight":"bestseller"}})],1):_vm._e(),(_vm.product.recommended)?_c('div',{class:_vm.product.bestseller ? 'recommended-tag' : 'recommended-only'},[_c('HighlightSVG',{attrs:{"highlight":"recommended"}})],1):_vm._e(),_c('img',{staticClass:"product-image",class:_vm.product.available === false ? 'unavailable-image' : '',attrs:{"src":_vm.product.image_url
                ? _vm.product.image_url
                : _vm.product.img_url
                ? _vm.product.img_url
                : require('@/assets/images/default-product-img.png')}}),_c('div',{staticClass:"product-details"},[_c('router-link',{attrs:{"to":{
                name: 'ProductDetailsPage',
                params: {
                    id: _vm.product.id,
                },
            },"target":"_blank"}},[_c('p',{staticClass:"product-name",class:_vm.product.available === false ? 'unavailable-text' : ''},[_vm._v(" "+_vm._s(_vm.product.name.slice(0, 25))+" "),(_vm.product.name.length > 25)?_c('span',[_vm._v("...")]):_vm._e()])]),_c('p',{class:_vm.product.available === false ? 'unavailable-text' : ''},[_vm._v(" "+_vm._s(_vm.product.vendor)+" ")])],1),(_vm.product.rate !== undefined)?_c('p',{staticClass:"product-rate"},[_vm._v(" ₹"+_vm._s(_vm.convertToIndianNumberSystem(_vm.product.rate))+" ")]):_vm._e(),(_vm.product.quantity !== undefined)?_c('p',{staticClass:"product-quantity"},[_vm._v(" "+_vm._s(_vm.product.quantity)+" ")]):_vm._e(),_c('p',{staticClass:"product-price",class:_vm.product.available === false ? 'unavailable-text' : ''},[_vm._v(" ₹"+_vm._s(_vm.convertToIndianNumberSystem( _vm.product.item_price ? _vm.product.item_price : _vm.product.price ? _vm.product.price : 0 ))+" ")]),(!_vm.product.category_group)?_c('div',{staticClass:"actions-custom"},[(_vm.product.active)?_c('div',{staticClass:"action-remove",on:{"click":function($event){return _vm.checkDraft({
                    product: _vm.product,
                    scene_id: _vm.scene_id,
                    operation: 'customProduct',
                    enable: false,
                    ehd_id: _vm.ehd_id,
                })}}},[_c('OperationsSVG',{attrs:{"operation":"remove"}})],1):_c('div',{staticClass:"action-remove",on:{"click":function($event){return _vm.checkDraft({
                    product: _vm.product,
                    scene_id: _vm.scene_id,
                    operation: 'customProduct',
                    enable: true,
                    ehd_id: _vm.ehd_id,
                })}}},[_vm._v(" + Add ")])]):_c('div',{staticClass:"actions"},[(_vm.product.active_quantity === 0)?[(_vm.product.available === true)?_c('div',{staticClass:"add-to-room",on:{"click":function($event){return _vm.checkDraft({
                        product: _vm.product,
                        scene_id: _vm.scene_id,
                        operation: 'restore',
                        enable: false,
                        ehd_id: _vm.ehd_id,
                    })}}},[_vm._v(" + ADD TO ROOM ")]):_c('div',{staticClass:"product-unavailable"},[_vm._v(" Product Unavailable ")])]:[_c('div',{staticClass:"action-change",on:{"click":function($event){return _vm.checkDraft({
                        product: _vm.product,
                        quantity: _vm.product.total_quantity,
                        scene_id: _vm.scene_id,
                        index: _vm.index,
                        operation: 'change',
                        ehd_id: _vm.ehd_id,
                    })}}},[_vm._v(" CHANGE ")]),(_vm.product.available === true)?_c('div',{staticClass:"number-of-products"},[(_vm.product.active_quantity !== 0)?_c('div',{staticClass:"remove-one",on:{"click":function($event){return _vm.checkDraft({
                            product: _vm.product,
                            scene_id: _vm.scene_id,
                            operation: 'count',
                            enable: false,
                            ehd_id: _vm.ehd_id,
                        })}}},[_c('OperationsSVG',{attrs:{"operation":"minus"}})],1):_c('div',{staticClass:"disable-icon"},[_c('OperationsSVG',{attrs:{"operation":"minus"}})],1),_c('div',{staticClass:"total"},[_vm._v(" "+_vm._s(_vm.product.active_quantity)+" ")]),_c('div',{staticClass:"add-one",on:{"click":function($event){return _vm.checkDraft({
                            product: _vm.product,
                            scene_id: _vm.scene_id,
                            operation: 'count',
                            enable: true,
                            ehd_id: _vm.ehd_id,
                        })}}},[_c('OperationsSVG',{attrs:{"operation":"plus"}})],1)]):_c('div',{staticClass:"product-unavailable"},[_vm._v(" Product Unavailable ")]),_c('div',{staticClass:"action-remove",on:{"click":function($event){return _vm.checkDraft({
                        product: _vm.product,
                        scene_id: _vm.scene_id,
                        operation: 'toggle',
                        enable: false,
                        ehd_id: _vm.ehd_id,
                    })}}},[_c('OperationsSVG',{attrs:{"operation":"remove"}})],1)]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }