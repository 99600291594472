<template>
    <div class="card-wrapper">
        <div class="card" :class="{ 'service-card': forServices }">
            <div class="card-image-wrapper" :id="`card_image_wrapper_${id}`">
                <img
                    class="card-img"
                    :src="img"
                    :alt="cardname"
                    :class="{
                        forServices: forServices == true,
                        notActive: notActive == true,
                    }"
                    :id="`card_image_${id}`"
                />
                <div class="card-quantity" v-if="quantity > 1">
                    {{ quantity }}
                </div>
            </div>
            <div class="content" :class="{ 'service-content': forServices }">
                <div v-if="forServices == true">
                    <p class="service-name">
                        {{ cardname.slice(0, 15) }}
                        <span v-if="cardname.length > 15">...</span>
                    </p>
                    <p class="service-description" v-if="windowWidth > 1100">
                        {{ description.slice(0, 40) }}
                        <span v-if="description.length > 40">...</span>
                    </p>
                    <p class="service-description" v-else>
                        {{ description.slice(0, 30) }}
                        <span v-if="description.length > 30">...</span>
                    </p>
                    <p
                        class="service-price"
                        v-if="price != 0"
                        :class="requiredDomain == false ? 'blur' : ''"
                    >
                        ₹ {{ convertToIndianNumberSystem(price) }}
                    </p>
                </div>
                <div v-else class="card-content">
                    <p class="card-name" v-if="windowWidth > 1100">
                        {{ cardname.slice(0, 14) }}
                        <span v-if="cardname.length > 14">...</span>
                    </p>
                    <p class="card-vendor">
                        {{ vendor }}
                    </p>
                    <p
                        class="card-price"
                        v-if="price != 0"
                        :class="requiredDomain == false ? 'blur' : ''"
                    >
                        ₹ {{ convertToIndianNumberSystem(price) }}
                    </p>
                </div>
            </div>
        </div>
        <div v-if="unavailable == true" class="card-overlay">
            <p class="text-unavailable">Product Unavailable</p>
        </div>
        <div
            class="available-3d"
            v-if="threeDavailable == true && available == true"
        >
            3D model available
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ConvertToPrice from 'mixins/ConvertToPrice'
export default {
    name: 'Cards',
    props: {
        img: String,
        cardname: String,
        description: String,
        price: Number,
        unavailable: Boolean,
        available: Boolean,
        threeDavailable: Boolean,
        quantity: Number,
        forServices: Boolean,
        vendor: String,
        notActive: Boolean,
        id: Number,
        requiredDomain: Boolean,
    },
    mixins: [ConvertToPrice],
    data() {
        return {}
    },
    computed: {
        ...mapState({
            windowWidth: state => state.AppModule.windowWidth,
        }),
    },
    async mounted() {
        var cardImage = document.getElementById(`card_image_wrapper_${this.id}`)
        // console.log(cardImage.clientHeight, cardImage.clientWidth, 'card')
        var img_html = document.getElementById(`card_image_${this.choiceID}`)
        let img = new Image()
        img.onload = function() {
            // console.log(img.naturalWidth, img.naturalHeight, 'image')
            if (img.naturalHeight > cardImage.clientHeight) {
                // console.log('height')
                img_html.classList.add('padding-class')
            } else if (img.naturalWidth > cardImage.clientWidth) {
                // console.log('width')
                img_html.classList.add('padding-class')
            } else if (
                img.naturalHeight > cardImage.clientHeight &&
                img.naturalWidth > cardImage.clientWidth
            ) {
                // console.log('height & width')
                img_html.classList.add('padding-class')
            } else {
                img_html.classList.add('cardImage-img')
            }
        }
        img.src = this.img
    },
}
</script>

<style lang="scss" scoped>
@import './NewCard.scss';
</style>
