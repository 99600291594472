<template>
    <div>
        <svg
            width="20px"
            height="20px"
            fill="#ff6100"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="48" height="48" fill="white" fill-opacity="0.01" />
            <path
                d="M6 6L16 15.8995"
                stroke="#ff6100"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6 41.8995L16 32"
                stroke="#ff6100"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M42.0001 41.8995L32.1006 32"
                stroke="#ff6100"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M41.8995 6L32 15.8995"
                stroke="#ff6100"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M33 6H42V15"
                stroke="#ff6100"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M42 33V42H33"
                stroke="#ff6100"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M15 42H6V33"
                stroke="#ff6100"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6 15V6H15"
                stroke="#ff6100"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'EhdFullScreenSVG',
}
</script>
