<template>
    <div class="filter-checkbox">
        <div class="filter-name">
            {{ filter.filter_name }}
        </div>
        <div class="checkbox-list">
            <div
                v-for="(option, index) in filter.options"
                :key="'option' + index"
            >
                <Checkbox
                    :label="option.name"
                    :inputValue="option.id"
                    v-model="selected"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Checkbox from 'componentsv2/Checkbox'

export default {
    name: 'FilterCheckBox',
    components: {
        Checkbox,
    },
    props: {
        filter: {
            required: true,
        },
        clearAll:Boolean,
    },
    data() {
        return {
            selected: [],
        }
    },
    watch: {
        selected: function (e) {
            this.$emit('SelectedChoices', {
                [this.filter.param]: e.join(),
            })
        },
        clearAll:function(e){
            if(e==true){
                this.selected=[]
                this.$emit('resetClearAll')
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import './FilterCheckBox.scss';
</style>
