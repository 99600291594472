<template>
    <div>
        <template v-if="operation === 'minus'">
            <svg viewbox="0 0 10 10">
                <line
                    x1="6"
                    y1="11.5"
                    x2="16"
                    y2="11.5"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    vector-effect="non-scaling-stroke"
                />
            </svg>
        </template>
        <template v-if="operation === 'plus'">
            <svg viewbox="0 0 10 10">
                <line
                    x1="6"
                    y1="11.5"
                    x2="15"
                    y2="11.5"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    vector-effect="non-scaling-stroke"
                />
                <line
                    y1="7"
                    x1="10.5"
                    y2="16"
                    x2="10.5"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    vector-effect="non-scaling-stroke"
                />
            </svg>
        </template>
        <template v-if="operation === 'remove'">
            <svg
                id="noun_dustbin_3666639"
                xmlns="http://www.w3.org/2000/svg"
                width="9.934"
                height="12.608"
                viewBox="0 0 9.934 12.608"
            >
                <g
                    id="Group_493"
                    data-name="Group 493"
                    transform="translate(0 0)"
                >
                    <path
                        id="Path_844"
                        data-name="Path 844"
                        class="cls-1"
                        d="M17.35,21l1.056,9.089a.955.955,0,0,0,.949.844H24a.955.955,0,0,0,.949-.844L26,21Zm2.8,8.191a.093.093,0,0,1-.023,0,.192.192,0,0,1-.189-.17l-.766-6.835a.191.191,0,0,1,.38-.042l.766,6.835A.191.191,0,0,1,20.149,29.191Zm1.719-.277a.191.191,0,1,1-.382,0V22.037a.191.191,0,0,1,.382,0Zm1.549.109a.192.192,0,0,1-.189.17.094.094,0,0,1-.023,0,.191.191,0,0,1-.168-.21l.766-6.835a.191.191,0,0,1,.38.042Z"
                        transform="translate(-16.71 -18.326)"
                    />
                    <path
                        id="Path_845"
                        data-name="Path 845"
                        class="cls-1"
                        d="M22.6,7.764H20.667A.955.955,0,0,0,19.731,7H18.2a.958.958,0,0,0-.936.764H15.337A1.339,1.339,0,0,0,14,9.1a.192.192,0,0,0,.191.191h9.552a.192.192,0,0,0,.191-.191A1.339,1.339,0,0,0,22.6,7.764ZM18.2,7.382h1.528a.576.576,0,0,1,.541.382H17.662A.576.576,0,0,1,18.2,7.382Z"
                        transform="translate(-14 -7)"
                    />
                </g>
            </svg>
        </template>
    </div>
</template>

<script>
export default {
    name: 'OperationsSVG',
    props: {
        customClass: {
            type: String,
            default: '',
        },
        operation: {
            type: String,
            default: '',
        },
    },
    computed: {
        customclass: function() {
            return this.customClass
        },
    },
}
</script>
