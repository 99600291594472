<template>
    <div>
        <div class="product-card">
            <div class="product-img">
                <router-link
                    :to="{
                        name: 'ProductDetailsPage',
                        params: {
                            id: product.id,
                        },
                    }"
                    target="_blank"
                >
                    <img
                        :src="
                            product.image_url
                                ? product.image_url
                                : product.img_url
                                ? product.img_url
                                : require('@/assets/images/default-product-img.png')
                        "
                        alt=""
                    />
                </router-link>
                <div class="best-seller-tag" v-if="product.bestseller">
                    <HighlightSVG highlight="bestseller" />
                    Popular
                </div>
            </div>
            <div>
                <div class="product-details">
                    <router-link
                        :to="{
                            name: 'ProductDetailsPage',
                            params: {
                                id: product.id,
                            },
                        }"
                        target="_blank"
                    >
                        <p class="bold-font">
                            {{ product.name.slice(0, 25) }}
                            <span v-if="product.name.length > 25">...</span>
                        </p>
                    </router-link>
                    <p>
                        {{ product.vendor }}
                    </p>
                    <p class="bold-font">
                        ₹{{
                            convertToIndianNumberSystem(
                                product.item_price
                                    ? product.item_price
                                    : product.price,
                            )
                        }}
                    </p>
                    <div class="recommended-tag" v-if="product.recommended">
                        <HighlightSVG highlight="recommended" />
                        Recommended by Siloho
                    </div>
                    <div v-else class="no-tag"></div>
                </div>
            </div>
            <div
                class="product-unavailable"
                v-if="product.available && !product.available"
            >
                Product Unavailable
            </div>
        </div>
        <div class="product-card-hover">
            <div class="product-img">
                <router-link
                    :to="{
                        name: 'ProductDetailsPage',
                        params: {
                            id: product.id,
                        },
                    }"
                    target="_blank"
                >
                    <img
                        :src="
                            product.image_url
                                ? product.image_url
                                : product.img_url
                                ? product.img_url
                                : require('@/assets/images/default-product-img.png')
                        "
                        alt=""
                    />
                </router-link>
                <div class="best-seller-tag" v-if="product.bestseller">
                    <HighlightSVG highlight="bestseller" />
                    Popular
                </div>
            </div>
            <div>
                <div class="product-details-hover">
                    <div class="actions" v-if="product.available">
                        <template v-if="product.active_quantity === 0">
                            <Button
                                name="ADD TO ROOM"
                                normal
                                class="customisation-button"
                                @click.native="
                                    checkDraft({
                                        product: product,
                                        quantity: product.total_quantity,
                                        scene_id: scene_id,
                                        index: index,
                                        operation: 'restore',
                                        ehd_id: ehd_id,
                                    })
                                "
                            />
                        </template>
                        <template v-else>
                            <div
                                v-if="product.available === true"
                                class="number-of-products"
                            >
                                <div
                                    class="remove-one"
                                    v-if="product.active_quantity !== 0"
                                    @click="
                                        checkDraft({
                                            product: product,
                                            scene_id: scene_id,
                                            operation: 'count',
                                            enable: false,
                                            ehd_id: ehd_id,
                                        })
                                    "
                                >
                                    <OperationsSVG operation="minus" />
                                </div>
                                <div v-else class="disable-icon">
                                    <OperationsSVG operation="minus" />
                                </div>
                                <div class="total">
                                    {{ product.active_quantity }}
                                </div>

                                <div
                                    class="add-one"
                                    @click="
                                        checkDraft({
                                            product: product,
                                            scene_id: scene_id,
                                            operation: 'count',
                                            enable: true,
                                            ehd_id: ehd_id,
                                        })
                                    "
                                >
                                    <OperationsSVG operation="plus" />
                                </div>
                            </div>

                            <Button
                                name="CHANGE"
                                normal
                                class="customisation-button"
                                @click.native="
                                    checkDraft({
                                        product: product,
                                        quantity: product.total_quantity,
                                        scene_id: scene_id,
                                        index: index,
                                        operation: 'change',
                                        ehd_id: ehd_id,
                                    })
                                "
                            />
                        </template>
                    </div>
                    <div class="price-remove">
                        <p
                            class="product-price"
                            :class="requiredDomain == false ? 'blur' : ''"
                        >
                            ₹
                            {{
                                convertToIndianNumberSystem(
                                    product.item_price
                                        ? product.item_price
                                        : product.price,
                                )
                            }}
                        </p>
                        <div
                            class="action-remove"
                            v-if="product.active_quantity !== 0"
                            @click="
                                checkDraft({
                                    product: product,
                                    scene_id: scene_id,
                                    operation: product.category_group
                                        ? 'toggle'
                                        : 'customProduct',
                                    enable: false,
                                    ehd_id: ehd_id,
                                })
                            "
                        >
                            <OperationsSVG operation="remove" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HighlightSVG from 'componentsv3/SVG/HighlightSVG'
import OperationsSVG from 'componentsv3/SVG/OperationsSVG'
import Button from 'componentsv2/Button'
import ConvertToPrice from 'mixins/ConvertToPrice'
export default {
    name: 'ProductCard',
    components: {
        HighlightSVG,
        OperationsSVG,
        Button,
    },
    mixins: [ConvertToPrice],
    props: {
        product: {
            type: Object,
            default: () => {},
        },
        index: {
            type: Number,
        },
        scene_id: {
            type: Number,
        },
        ehd_id: {
            type: Number,
        },
        requiredDomain: {
            type: Boolean,
        },
    },
    data() {
        return {
            hover: false,
        }
    },
    methods: {
        checkDraft(obj) {
            this.$emit('checkDraft', obj)
        },
    },
}
</script>

<style lang="scss" scoped></style>
