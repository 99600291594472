<template>
    <div
        class="workspace-card"
        :class="draftBySpace == true ? 'draft-by-space' : ''"
    >
        <div class="workspace-header">
            <div class="time-created">Updated {{ calculateTime }}</div>
            <div class="room-actions" v-if="hideButtons != true">
                <div class="edit-room" @click="editScene">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.189"
                        height="15.134"
                        viewBox="0 0 16.189 15.134"
                    >
                        <g
                            id="icon_content_create_24px"
                            data-name="icon/content/create_24px"
                            transform="translate(4.32 -0.68)"
                        >
                            <path
                                id="_Color"
                                data-name=" ↳Color"
                                class="cls-1"
                                d="M3.373,15.134H0V11.981l9.947-9.3,3.373,3.153Zm10.908-10.2h0L10.909,1.784,12.555.246a.941.941,0,0,1,1.268,0l2.1,1.967a.8.8,0,0,1,0,1.186L14.282,4.936Z"
                                transform="translate(-4.32 0.68)"
                            />
                        </g>
                    </svg>
                </div>
                <div class="delete-room" @click="deleteScene">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14.962"
                        height="15.133"
                        viewBox="0 0 14.962 15.133"
                    >
                        <g
                            id="noun_Delete_744231"
                            transform="translate(-15 -15.1)"
                        >
                            <path
                                id="Path_807"
                                data-name="Path 807"
                                class="cls-1"
                                d="M29.321,16.874H25.687v-.791a1.068,1.068,0,0,0-1.154-.983h-4.1a1.068,1.068,0,0,0-1.154.983v.791H15.641a.641.641,0,1,0,0,1.282h.791l.791,9.961a2.139,2.139,0,0,0,2.137,2.116h6.22a2.139,2.139,0,0,0,2.137-2.116l.812-9.939h.791a.63.63,0,0,0,.641-.641A.648.648,0,0,0,29.321,16.874Zm-8.764-.492H24.4v.492H20.557Zm.256,10.409h-.021a.654.654,0,0,1-.641-.62l-.235-5.215a.618.618,0,0,1,.62-.663h.021a.654.654,0,0,1,.641.62l.235,5.215A.657.657,0,0,1,20.814,26.792Zm4-.62a.654.654,0,0,1-.641.62h-.021a.657.657,0,0,1-.62-.663l.235-5.215a.654.654,0,0,1,.641-.62h.021a.657.657,0,0,1,.62.663Z"
                                transform="translate(0)"
                            />
                        </g>
                    </svg>
                </div>
            </div>
        </div>
        <div class="draft-details">
            <div class="img-wrapper">
                <img
                    :src="
                        draftImage
                            ? draftImage
                            : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                    "
                />
            </div>
            <div class="workspace-details">
                <ul>
                    <li :class="requiredDomain == false ? 'blur' : ''">
                        ₹{{ convertToIndianNumberSystem(price) }}
                    </li>
                    <li>{{ noOfProducts }} Products</li>
                    <li>{{ noOfServices }} Services</li>
                </ul>
            </div>
            <div class="original-space">
                <p class="og-label">Original Room Info :</p>
                <div class="img-info-wrapper">
                    <img
                        :src="
                            ogDetails.scene_image
                                ? ogDetails.scene_image
                                : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                        "
                    />
                    <div class="og-details">
                        <ul>
                            <li :class="requiredDomain == false ? 'blur' : ''">
                                ₹{{
                                    convertToIndianNumberSystem(ogDetails.price)
                                }}
                            </li>
                            <li>{{ ogDetails.no_of_products }} Products</li>
                            <li>{{ ogDetails.no_of_services }} Services</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="workspace-footer">{{ theme }} Style | {{ footerName }}</div>
    </div>
</template>
<script>
// import Carousel from 'componentsv2/Carousel'
import ConvertToPrice from 'mixins/ConvertToPrice'
export default {
    name: 'WorkspaceComponent',
    components: {
        //Carousel,
    },
    mixins: [ConvertToPrice],
    props: {
        timeCreated: String,
        draftImage: String,
        ogDetails: Object,
        theme: String,
        price: Number,
        footerName: String,
        noOfProducts: Number,
        noOfServices: Number,
        sceneid: Number,
        ehdid: Number,
        draftBySpace: Boolean,
        hideButtons: Boolean,
        requiredDomain: Boolean,
    },
    computed: {
        calculateTime() {
            const _MS_PER_DAY = 1000 * 60 * 60
            var seconds = Math.floor(
                (this.currentTime.getTime() - this.createdTime.getTime()) /
                    1000,
            )
            var minutes = Math.floor(seconds / 60)
            var hours = Math.floor(minutes / 60)
            var days = Math.floor(hours / 24)
            var months = Math.floor(days / 31)
            var years = Math.floor(months / 12)
            hours = hours - days * 24
            if (years > 0) {
                return years + (years > 1 ? ' Years Ago' : ' Year Ago')
            } else if (months > 0) {
                return months + (months > 1 ? ' Days Ago' : ' Month Ago')
            } else if (days > 0) {
                return days + (days > 1 ? ' Days Ago' : ' Day Ago')
            } else {
                return hours + (hours > 1 ? ' Hours Ago' : ' Hour Ago')
            }
        },
    },
    data() {
        return {
            currentTime: new Date(),
            createdTime: new Date(this.timeCreated),

            // settings: {
            //     arrows: true,
            //     focusOnSelect: false,
            //     speed: 500,
            //     slidesToShow: 2,
            //     infinite: false,
            //     slidesToScroll: 1,
            //     touchThreshold: 5,
            //     responsive: [
            //         {
            //             breakpoint: 1200,
            //             settings: {
            //                 slidesToShow: 2,
            //                 slidesToScroll: 1,
            //             },
            //         },
            //         {
            //             breakpoint: 500,
            //             settings: {
            //                 slidesToShow: 1,
            //                 slidesToScroll: 1,
            //             },
            //         },
            //     ],
            // },
        }
    },
    methods: {
        deleteScene() {
            this.$emit('deleteScene', {
                scene_id: this.sceneid,
            })
        },
        editScene() {
            if (this.ehdid != null) {
                this.$emit('editScene', {
                    scene_id: this.sceneid,
                    ehd_id: this.ehdid,
                })
            } else {
                console.log('ehd id is null')
            }
        },
    },
}
</script>
<style lang="scss" scoped>
@import './WorkspaceComponent.scss';
</style>
