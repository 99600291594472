var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"single-service"},[_c('div',{staticClass:"service-category"},[_c('img',{staticClass:"service-image",attrs:{"src":_vm.service.icon_url
                    ? _vm.service.icon_url
                    : require('@/assets/images/enable_service.png')}}),_c('p',{staticClass:"service-type"},[_vm._v(" "+_vm._s(_vm.service.type)+" ")]),_c('div',{staticClass:"actions"},[(_vm.active)?_c('div',{staticClass:"action-remove",on:{"click":function($event){return _vm.checkDraft({
                        operation: 'allService',
                        type: _vm.service.type,
                        scene_id: _vm.scene_id,
                        enable: false,
                        ehd_id: _vm.ehd_id,
                    })}}},[_c('OperationsSVG',{attrs:{"operation":"remove"}})],1):_c('div',{staticClass:"action-remove",on:{"click":function($event){return _vm.checkDraft({
                        operation: 'allService',
                        type: _vm.service.type,
                        scene_id: _vm.scene_id,
                        enable: true,
                        ehd_id: _vm.ehd_id,
                    })}}},[_vm._v(" + Add ")])])]),_vm._l((_vm.service.data),function(services){return _c('div',{key:services.id,staticClass:"service-info-removed"},[_c('img',{staticClass:"removed-service-image",attrs:{"src":_vm.service.service_icon_url
                    ? _vm.service.service_icon_url
                    : require('@/assets/images/enable_service.png')}}),_c('div',{staticClass:"service-details"},[_c('p',{staticClass:"service-name"},[_vm._v(" "+_vm._s(services.name)+" ")])]),_c('p',{staticClass:"service-price"},[_vm._v(" ₹"+_vm._s(_vm.convertToIndianNumberSystem(services.rate))+" "+_vm._s(services.unit)+" ")]),_c('p',{staticClass:"service-price"},[_vm._v(" "+_vm._s(services.quantity)+" ")]),_c('p',{staticClass:"service-price"},[_vm._v(" ₹"+_vm._s(_vm.convertToIndianNumberSystem(services.price))+" ")]),_c('div',{on:{"click":function($event){return _vm.showDescription(services.description)}}},[_c('HighlightSVG',{attrs:{"highlight":"info"}})],1),_c('div',{staticClass:"actions"},[(_vm.active)?_c('div',{staticClass:"action-remove",on:{"click":function($event){return _vm.checkDraft({
                        operation: 'service',
                        scene_id: _vm.scene_id,
                        enable: false,
                        ehd_id: _vm.ehd_id,
                        service_id: services.id,
                    })}}},[_c('OperationsSVG',{attrs:{"operation":"remove"}})],1):_c('div',{staticClass:"action-remove",on:{"click":function($event){return _vm.checkDraft({
                        operation: 'service',
                        scene_id: _vm.scene_id,
                        enable: true,
                        ehd_id: _vm.ehd_id,
                        service_id: services.id,
                    })}}},[_vm._v(" + Add ")])])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }