import { getEHDDetails, getEHDDetailsv3 } from 'api/ehdApi'
import { restoreDraft, restoreDraftv3 } from 'api/userApi'

const EHDPage = {
    state: () => ({
        ogTagDetails: {},
        EHDDetails: {},
        ehdShowModal: false,
        ehdModalMsg: '',
        totalProducts: {},
        totalServices: {},
        totalCustomProducts: {},
        roomData: [],
        totalCost: 0,
    }),

    mutations: {
        setTotalCost(state, payload) {
            state.totalCost = payload
        },
        setRoomData(state, payload) {
            state.roomData = payload
        },
        setEHDDetails(state, payload) {
            state.EHDDetails = payload
        },
        setEhdShowModal(state, payload) {
            state.ehdShowModal = payload
        },
        setEhdModalMsg(state, payload) {
            state.ehdModalMsg = payload
        },
        setTotalProducts(state, payload) {
            state.totalProducts = payload
        },
        setTotalServices(state, payload) {
            state.totalServices = payload
        },
        setTotalCustomProducts(state, payload) {
            state.totalCustomProducts = payload
        },
        setOGTagDetails(state, payload) {
            state.ogTagDetails = payload
        },
    },

    getters: {},

    actions: {
        // fetchOGTagEHDDetails({ commit }, id) {
        //     commit('setOGTagDetails', {})
        //     return fetchEhdDetails(id).then(response => {
        //         if (response.responseCode == 200) {
        //             let ogTagData = response.data.data

        //         }
        //     })
        // },

        toggleCustomProduct({ commit, state }, response) {
            const updatedRoomProducts = [...state.roomData]
            const scene = updatedRoomProducts.findIndex(scene => {
                return scene.sceneId === response.payload.scene_id
            })
            updatedRoomProducts[scene].removedCustomProducts =
                response.payload.changes_data.inactive_one_time_custom_product
            updatedRoomProducts[scene].customProducts =
                response.payload.changes_data.one_time_custom_product
            updatedRoomProducts[scene].productCost +=
                response.payload.changes_data.price_change
            state.totalCost += response.payload.changes_data.price_change
            updatedRoomProducts[scene].activeProductCount +=
                response.payload.changes_data.count_change
            commit('setRoomData', updatedRoomProducts)
        },

        toggleService({ commit, state }, response) {
            const updatedRoomProducts = [...state.roomData]
            const scene = updatedRoomProducts.findIndex(scene => {
                return scene.sceneId === response.payload.scene_id
            })
            updatedRoomProducts[scene].removedServices =
                response.payload.changes_data.services
            updatedRoomProducts[scene].services =
                response.payload.changes_data.base_services
            updatedRoomProducts[scene].serviceCost =
                response.payload.changes_data.price_of_services
            updatedRoomProducts[scene].activeServiceCount =
                response.payload.changes_data.active_services
            state.totalCost = response.payload.changes_data.price_of_services
            commit('setRoomData', updatedRoomProducts)
        },

        replaceBaseProduct({ commit, state }, response) {
            const oldProduct = response.payload.changes_data.product.old
            const newProduct = response.payload.changes_data.product.new
            const updatedRoomProducts = [...state.roomData]
            const scene = updatedRoomProducts.findIndex(scene => {
                return scene.sceneId === response.payload.scene_id
            })
            const oldProductIndex = updatedRoomProducts[
                scene
            ].products.findIndex(prod => {
                return prod.id === oldProduct.id
            })

            if (oldProduct.total_quantity === 0) {
                updatedRoomProducts[scene].products.splice(oldProductIndex, 1)
            } else {
                if (oldProduct.active_quantity === 0) {
                    const removedProduct = updatedRoomProducts[
                        scene
                    ].products.splice(oldProductIndex, 1)
                    updatedRoomProducts[scene].removedProducts[
                        oldProduct.category_group
                    ].concat(removedProduct)
                } else {
                    updatedRoomProducts[scene].products[
                        oldProductIndex
                    ] = oldProduct
                }
            }
            if (
                newProduct.total_quantity > 1 &&
                newProduct.active_quantity === 1
            ) {
                const temp = updatedRoomProducts[scene].removedProducts[
                    newProduct.category_group
                ].filter(prod => {
                    return prod.id !== newProduct.id
                })
                if (temp.length > 0) {
                    updatedRoomProducts[scene].removedProducts[
                        newProduct.category_group
                    ] = temp
                } else {
                    updatedRoomProducts[
                        scene
                    ].categoryGroups = updatedRoomProducts[
                        scene
                    ].categoryGroups.filter(categry => {
                        return categry !== newProduct.category_group
                    })
                    delete updatedRoomProducts[scene].removedProducts[
                        newProduct.category_group
                    ]
                }
            }
            if (newProduct.active_quantity > 1) {
                const newProductIndex = updatedRoomProducts[
                    scene
                ].products.findIndex(prod => {
                    return prod.id === newProduct.id
                })
                updatedRoomProducts[scene].products[newProductIndex] = {
                    ...newProduct,
                }
            } else {
                updatedRoomProducts[scene].products = updatedRoomProducts[
                    scene
                ].products.concat(newProduct)
            }
            updatedRoomProducts[scene].productCost =
                response.payload.changes_data.price_change
            state.totalCost += response.payload.changes_data.price_change
            commit('setRoomData', updatedRoomProducts)
        },

        changeBaseProductCount({ commit, state }, response) {
            const updatedRoomProducts = [...state.roomData]
            const scene = updatedRoomProducts.findIndex(scene => {
                return scene.sceneId === response.payload.scene_id
            })
            const changed_product = updatedRoomProducts[
                scene
            ].products.findIndex(product => {
                return product.id === response.payload.changes_data.product.id
            })
            updatedRoomProducts[scene].products[changed_product] = {
                ...response.payload.changes_data.product,
            }
            updatedRoomProducts[scene].productCost +=
                response.payload.changes_data.price_change
            updatedRoomProducts[scene].activeProductCount +=
                response.payload.changes_data.count_change
            state.totalCost += response.payload.changes_data.price_change
            commit('setRoomData', updatedRoomProducts)
        },

        removeProduct({ commit, state }, response) {
            // console.log("hi")
            // console.log(response.changes_data)
            // console.log('hi2')
            const updatedRoomProducts = [...state.roomData]
            const scene = updatedRoomProducts.findIndex(scene => {
                return scene.sceneId === response.payload.scene_id
            })
            const changed_product = updatedRoomProducts[scene].products.filter(
                product => {
                    return (
                        product.id !== response.payload.changes_data.product.id
                    )
                },
            )
            updatedRoomProducts[scene].products = changed_product
            const product = response.payload.changes_data.product
            if (
                updatedRoomProducts[scene].removedProducts[
                    product.category_group
                ] === undefined ||
                updatedRoomProducts[scene].removedProducts[
                    product.category_group
                ].length === 0
            ) {
                updatedRoomProducts[scene].categoryGroups.push(
                    product.category_group,
                )
                updatedRoomProducts[scene].removedProducts[
                    product.category_group
                ] = [product]
            } else {
                updatedRoomProducts[scene].removedProducts[
                    product.category_group
                ].push(product)
            }
            if (response.payload.changes_data.product.available) {
                updatedRoomProducts[scene].productCost +=
                    response.payload.changes_data.price_change
                state.totalCost += response.payload.changes_data.price_change
            }
            updatedRoomProducts[scene].activeProductCount +=
                response.payload.changes_data.count_change
            commit('setRoomData', updatedRoomProducts)
        },

        addAllProducts({ commit, state }, response) {
            const updatedRoomProducts = [...state.roomData]
            const scene = updatedRoomProducts.findIndex(scene => {
                return scene.sceneId === response.payload.scene_id
            })
            const product = response.payload.changes_data.product
            const category =
                response.payload.changes_data.product.category_group ===
                undefined
                    ? response.payload.changes_data.product[0].category_group
                    : response.payload.changes_data.product.category_group

            updatedRoomProducts[scene].products = updatedRoomProducts[
                scene
            ].products.concat(product)
            delete updatedRoomProducts[scene].removedProducts[category]
            if (
                updatedRoomProducts[scene].removedProducts[category] ===
                    undefined ||
                updatedRoomProducts[scene].removedProducts[category].length ===
                    0
            ) {
                updatedRoomProducts[scene].categoryGroups = updatedRoomProducts[
                    scene
                ].categoryGroups.filter(categry => {
                    return categry !== category
                })
            }
            updatedRoomProducts[scene].productCost +=
                response.payload.changes_data.price_change
            updatedRoomProducts[scene].activeProductCount +=
                response.payload.changes_data.count_change
            state.totalCost += response.payload.changes_data.price_change
            commit('setRoomData', updatedRoomProducts)
        },

        addNewProduct({ commit, state }, response) {
            console.log(response)
            const updatedRoomProducts = [...state.roomData]
            const scene = updatedRoomProducts.findIndex(scene => {
                return scene.sceneId === response.payload.scene_id
            })
            const product = response.payload.changes_data.product
            const category =
                response.payload.changes_data.product.category_group ===
                undefined
                    ? response.payload.changes_data.product[0].category_group
                    : response.payload.changes_data.product.category_group
            if (
                response.payload.changes_data.product.total_quantity >
                response.payload.changes_data.product.active_quantity
            ) {
                updatedRoomProducts[scene].removedProducts[
                    category
                ] = updatedRoomProducts[scene].removedProducts[category].filter(
                    prod => {
                        return prod.id !== product.id
                    },
                )
            }
            updatedRoomProducts[scene].products.push(product)

            if (
                updatedRoomProducts[scene].removedProducts[category] ===
                    undefined ||
                updatedRoomProducts[scene].removedProducts[category].length ===
                    0
            ) {
                updatedRoomProducts[scene].categoryGroups = updatedRoomProducts[
                    scene
                ].categoryGroups.filter(categry => {
                    return categry !== category
                })
            }
            if (response.payload.changes_data.product.available) {
                updatedRoomProducts[scene].productCost +=
                    response.payload.changes_data.price_change
                state.totalCost += response.payload.changes_data.price_change
            }
            updatedRoomProducts[scene].activeProductCount +=
                response.payload.changes_data.count_change
            commit('setRoomData', updatedRoomProducts)
        },

        addProduct({ commit, state }, response) {
            const updatedRoomProducts = [...state.roomData]
            const scene = updatedRoomProducts.findIndex(scene => {
                return scene.sceneId === response.payload.scene_id
            })
            const product = response.payload.changes_data.product
            const category =
                response.payload.changes_data.product.category_group ===
                undefined
                    ? response.payload.changes_data.product[0].category_group
                    : response.payload.changes_data.product.category_group

            updatedRoomProducts[scene].removedProducts[
                category
            ] = updatedRoomProducts[scene].removedProducts[category].filter(
                prod => {
                    return prod.id !== product.id
                },
            )
            updatedRoomProducts[scene].products.push(product)

            if (
                updatedRoomProducts[scene].removedProducts[category] ===
                    undefined ||
                updatedRoomProducts[scene].removedProducts[category].length ===
                    0
            ) {
                updatedRoomProducts[scene].categoryGroups = updatedRoomProducts[
                    scene
                ].categoryGroups.filter(categry => {
                    return categry !== category
                })
            }
            if (response.payload.changes_data.product.available) {
                updatedRoomProducts[scene].productCost +=
                    response.payload.changes_data.price_change
                state.totalCost += response.payload.changes_data.price_change
            }
            updatedRoomProducts[scene].activeProductCount +=
                response.payload.changes_data.count_change
            commit('setRoomData', updatedRoomProducts)
        },

        fetchEHDDetailsv3({ commit }, id) {
            commit('setEHDDetails', {})
            commit('setEhdShowModal', false)
            commit('setEhdModalMsg', '')
            commit('setTotalProducts', {})
            commit('setTotalServices', {})
            commit('setTotalCustomProducts', {})
            commit('setRoomData', [])
            return getEHDDetailsv3({ ehd_id: id }).then(response => {
                if (response.responseCode == 200) {
                    let data = response.data
                    let roomDataArray = []
                    let total = 0

                    data.scene_data.forEach(scene => {
                        roomDataArray.push({
                            activeProductCount: scene.active_products_count,
                            activeServiceCount: scene.active_services,
                            productCost:
                                scene.price_of_products +
                                scene.custom_product_cost,
                            serviceCost: scene.price_of_services,
                            sceneId: scene.id,
                            products: scene.base_products,
                            removedProducts: scene.products,
                            categoryGroups: scene.category_groups,
                            customProducts: scene.one_time_custom_product,
                            removedCustomProducts:
                                scene.inactive_one_time_custom_product,
                            services: scene.base_services,
                            removedServices: scene.services,
                        })
                        total +=
                            scene.price_of_services +
                            scene.price_of_products +
                            scene.custom_product_cost
                    })

                    commit('setRoomData', roomDataArray)
                    commit('setTotalCost', total)
                    commit('setEHDDetails', response.data)
                    commit('setEhdShowModal', false)
                    commit('setEhdModalMsg', '')

                    return response
                } else {
                    commit('setEHDDetails', {})
                    commit('setEhdShowModal', true)
                    commit('setEhdModalMsg', response.responseMessage)
                    return response
                }
            })
        },
        fetchEHDDetails({ commit }, obj) {
            commit('setEHDDetails', {})
            commit('setEhdShowModal', false)
            commit('setEhdModalMsg', '')
            commit('setTotalProducts', {})
            commit('setTotalServices', {})
            commit('setTotalCustomProducts', {})
            return getEHDDetails(obj.id, obj.version).then(response => {
                if (response.responseCode == 200) {
                    let scenes = response.data.scene_data
                    let sceneData = []
                    let servicesData = []
                    let customData = []
                    scenes.forEach((scene, index) => {
                        let active = []
                        let removed = []
                        let active_services = []
                        let removed_services = []
                        let active_custom = []
                        let removed_custom = []
                        if (
                            scene.furnishing_products_in_scene_grouped.length ==
                            0
                        ) {
                            active.push()
                            removed.push()
                        } else {
                            scene.furnishing_products_in_scene_grouped.forEach(
                                product => {
                                    if (product.active_quantity != 0) {
                                        active.push(product)
                                    } else {
                                        removed.push(product)
                                    }
                                },
                            )
                        }
                        if (scene.decor_products_in_scene_grouped.length == 0) {
                            active.push()
                            removed.push()
                        } else {
                            scene.decor_products_in_scene_grouped.forEach(
                                product => {
                                    if (product.active_quantity != 0) {
                                        active.push(product)
                                    } else {
                                        removed.push(product)
                                    }
                                },
                            )
                        }
                        if (scene.services_data.length == 0) {
                            active_services.push()
                            removed_services.push()
                        } else {
                            scene.services_data.forEach(service => {
                                if (service.active == true) {
                                    active_services.push(service)
                                } else {
                                    removed_services.push(service)
                                }
                            })
                        }
                        if (scene.one_time_custom_product.length == 0) {
                            active_custom.push()
                            removed_custom.push()
                        } else {
                            scene.one_time_custom_product.forEach(custom => {
                                if (custom.active == true) {
                                    active_custom.push(custom)
                                } else {
                                    removed_custom.push(custom)
                                }
                            })
                        }
                        servicesData[index] = {
                            scene_id: scene.id,
                            active: active_services,
                            removed: removed_services,
                        }
                        sceneData[index] = {
                            scene_id: scene.id,
                            active: active,
                            removed: removed,
                        }
                        customData[index] = {
                            scene_id: scene.id,
                            active: active_custom,
                            removed: removed_custom,
                        }
                    })
                    commit('setEHDDetails', response.data)
                    commit('setEhdShowModal', false)
                    commit('setEhdModalMsg', '')
                    commit('setTotalProducts', sceneData)
                    commit('setTotalServices', servicesData)
                    commit('setTotalCustomProducts', customData)
                    return response
                } else {
                    commit('setEHDDetails', {})
                    commit('setEhdShowModal', true)
                    commit('setEhdModalMsg', response.responseMessage)
                    return response
                }
            })
        },
        restoreDraftDetails({ commit }, object) {
            return restoreDraft(object).then(response => {
                if (response.responseCode == 200) {
                    commit('setProjectLocation', response.payload)
                    return response
                } else {
                    console.log('restore failed')
                }
            })
        },
        restoreDraftDetailsv3({ commit }, object) {
            return restoreDraftv3(object).then(response => {
                if (response.responseCode == 200) {
                    commit('setProjectLocation', response.payload)
                    return response
                } else {
                    console.log('restore failed')
                }
            })
        },
    },
}

export default EHDPage
