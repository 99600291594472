<template>
    <div>
        <div v-if="view === 'list'">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.963"
                height="13.235"
                viewBox="0 0 15.963 13.235"
            >
                <g id="noun-list-view-1055380" transform="translate(0 0)">
                    <path
                        id="Path_2877"
                        data-name="Path 2877"
                        d="M188.946,141.1a1.141,1.141,0,1,1-1.14-1.1,1.122,1.122,0,0,1,1.14,1.1"
                        transform="translate(-186.667 -139.998)"
                        :fill="fill"
                    />
                    <path
                        id="Path_2878"
                        data-name="Path 2878"
                        d="M281.138,140h9.122a1.2,1.2,0,0,1,1.14,1.1,1.122,1.122,0,0,1-1.14,1.1h-9.122a1.2,1.2,0,0,1-1.14-1.1A1.122,1.122,0,0,1,281.138,140Z"
                        transform="translate(-275.438 -140)"
                        :fill="fill"
                    />
                    <path
                        id="Path_2879"
                        data-name="Path 2879"
                        d="M188.946,257.771a1.141,1.141,0,1,1-1.14-1.1,1.122,1.122,0,0,1,1.14,1.1"
                        transform="translate(-186.667 -251.153)"
                        :fill="fill"
                    />
                    <path
                        id="Path_2880"
                        data-name="Path 2880"
                        d="M281.138,256.67h9.122a1.2,1.2,0,0,1,1.14,1.1,1.122,1.122,0,0,1-1.14,1.1h-9.122a1.2,1.2,0,0,1-1.14-1.1A1.122,1.122,0,0,1,281.138,256.67Z"
                        transform="translate(-275.438 -251.155)"
                        :fill="fill"
                    />
                    <path
                        id="Path_2881"
                        data-name="Path 2881"
                        d="M188.946,374.437a1.141,1.141,0,1,1-1.14-1.1,1.122,1.122,0,0,1,1.14,1.1"
                        transform="translate(-186.667 -362.305)"
                        :fill="fill"
                    />
                    <path
                        id="Path_2882"
                        data-name="Path 2882"
                        d="M281.138,373.33h9.122a1.2,1.2,0,0,1,1.14,1.1,1.122,1.122,0,0,1-1.14,1.1h-9.122a1.2,1.2,0,0,1-1.14-1.1A1.122,1.122,0,0,1,281.138,373.33Z"
                        transform="translate(-275.438 -362.301)"
                        :fill="fill"
                    />
                </g>
            </svg>
        </div>
        <div v-if="view === 'thumb'">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="thumb-icon"
                width="13.55"
                height="13.235"
                viewBox="0 0 13.55 13.235"
            >
                <g id="noun-view-list-1823906" transform="translate(0 0)">
                    <path
                        id="Path_2883"
                        data-name="Path 2883"
                        :class="customclass"
                        d="M170.047,103.689h4.993a.623.623,0,0,0,.632-.612v-4.83a.623.623,0,0,0-.632-.612h-4.993a.623.623,0,0,0-.632.612v4.83A.623.623,0,0,0,170.047,103.689Zm0-5.443h4.993l0,0,0,4.831-4.995,0Z"
                        transform="translate(-169.415 -97.636)"
                        :fill="fill"
                    />
                    <path
                        id="Path_2884"
                        data-name="Path 2884"
                        :class="customclass"
                        d="M364.423,103.689h4.993a.623.623,0,0,0,.632-.612v-4.83a.623.623,0,0,0-.632-.612h-4.993a.623.623,0,0,0-.633.612v4.83A.623.623,0,0,0,364.423,103.689Z"
                        transform="translate(-356.499 -97.636)"
                        :fill="fill"
                    />
                    <path
                        id="Path_2885"
                        data-name="Path 2885"
                        :class="customclass"
                        d="M170.047,301.609h4.993a.623.623,0,0,0,.632-.612v-4.83a.623.623,0,0,0-.632-.612h-4.993a.623.623,0,0,0-.632.612V301A.623.623,0,0,0,170.047,301.609Z"
                        transform="translate(-169.415 -288.374)"
                        :fill="fill"
                    />
                    <path
                        id="Path_2886"
                        data-name="Path 2886"
                        :class="customclass"
                        d="M364.423,301.609h4.993a.623.623,0,0,0,.632-.612v-4.83a.623.623,0,0,0-.632-.612h-4.993a.623.623,0,0,0-.633.612V301A.623.623,0,0,0,364.423,301.609Z"
                        transform="translate(-356.499 -288.374)"
                        :fill="fill"
                    />
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CategoryListViewSVG',
    props: {
        fill: {
            type: String,
            default: '#ff6100',
        },
        view: {
            type: String,
            default: 'list',
        },
        customClass: {
            type: String,
            default: '',
        },
    },
    computed: {
        customclass: function() {
            return this.customClass
        },
    },
}
</script>
