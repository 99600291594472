<template>
    <div class="single-service">
        <div class="service-category">
            <img
                :src="
                    service.icon_url
                        ? service.icon_url
                        : require('@/assets/images/enable_service.png')
                "
                class="service-image"
            />
            <p class="service-type">
                {{ service.type }}
            </p>
            <div class="actions">
                <div
                    v-if="active"
                    class="action-remove"
                    @click="
                        checkDraft({
                            operation: 'allService',
                            type: service.type,
                            scene_id: scene_id,
                            enable: false,
                            ehd_id: ehd_id,
                        })
                    "
                >
                    <OperationsSVG operation="remove" />
                </div>
                <div
                    v-else
                    class="action-remove"
                    @click="
                        checkDraft({
                            operation: 'allService',
                            type: service.type,
                            scene_id: scene_id,
                            enable: true,
                            ehd_id: ehd_id,
                        })
                    "
                >
                    + Add
                </div>
            </div>
        </div>
        <div
            v-for="services in service.data"
            class="service-info-removed"
            :key="services.id"
        >
            <img
                :src="
                    service.service_icon_url
                        ? service.service_icon_url
                        : require('@/assets/images/enable_service.png')
                "
                class="removed-service-image"
            />
            <div class="service-details">
                <p class="service-name">
                    {{ services.name }}
                </p>
            </div>
            <p class="service-price">
                ₹{{ convertToIndianNumberSystem(services.rate) }}
                {{ services.unit }}
            </p>
            <p class="service-price">
                {{ services.quantity }}
            </p>

            <p class="service-price">
                ₹{{ convertToIndianNumberSystem(services.price) }}
            </p>
            <div @click="showDescription(services.description)">
                <HighlightSVG highlight="info" />
            </div>
            <div class="actions">
                <div
                    v-if="active"
                    class="action-remove"
                    @click="
                        checkDraft({
                            operation: 'service',
                            scene_id: scene_id,
                            enable: false,
                            ehd_id: ehd_id,
                            service_id: services.id,
                        })
                    "
                >
                    <OperationsSVG operation="remove" />
                </div>
                <div
                    v-else
                    class="action-remove"
                    @click="
                        checkDraft({
                            operation: 'service',
                            scene_id: scene_id,
                            enable: true,
                            ehd_id: ehd_id,
                            service_id: services.id,
                        })
                    "
                >
                    + Add
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OperationsSVG from 'componentsv3/SVG/OperationsSVG'
import HighlightSVG from 'componentsv3/SVG/HighlightSVG'
import ConvertToPrice from 'mixins/ConvertToPrice'
export default {
    name: 'ServiceBar',
    components: {
        OperationsSVG,
        HighlightSVG,
    },
    mixins: [ConvertToPrice],
    props: {
        service: {
            type: Object,
            default: () => {},
        },
        active: {
            type: Boolean,
        },
        scene_id: {
            type: Number,
        },
        ehd_id: {
            type: Number,
        },
    },
    methods: {
        checkDraft(obj) {
            this.$emit('checkDraft', obj)
        },
        showDescirption(description){
            this.$emit('showDescirption',description)
        }
    },
}
</script>

<style lang="scss" scoped></style>
