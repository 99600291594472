<template>
    <div>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22.205"
            height="19"
            viewBox="0 0 22.205 19"
        >
            <path
                data-name="Path 2444"
                d="M29.964 68.874a1.426 1.426 0 0 1-1.426 1.426H13.175a1.426 1.426 0 0 1-1.426-1.426v-12.16a1.426 1.426 0 0 1 1.426-1.426h15.363a1.426 1.426 0 0 1 1.426 1.426zm-1.9-1.176-3.3-7.006-4.1 5.1-3-3.3-4 5.2zm-10.71-8.707a1.1 1.1 0 1 1-1.1-1.1 1.1 1.1 0 0 1 1.1 1.1zm14.174 8.322a1.426 1.426 0 0 0 1.426-1.426V53.726a1.426 1.426 0 0 0-1.426-1.426H16.165a1.426 1.426 0 0 0-1.426 1.426"
                transform="translate(-11.249 -51.8)"
                style="fill:none;stroke:#ff6100;stroke-miterlimit:10"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'EhdMoreImageButtonSVG',
}
</script>
