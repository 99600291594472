<template>
    <div style="width: 100%; height: 100%;">
        <div class="panoimage" id="panoimage"></div>
    </div>
</template>

<script>
import * as PanoLens from 'panolens'
import * as THREE from 'three'

export default {
    name: 'PanoImage',
    props: {
        source: {
            type: String,
            default: '',
        },
        replaceable: {
            type: Array,
        },
        type: {
            type: String,
            default: 'image',
        },
        width: {
            type: Number,
            default: 100,
        },
        height: {
            type: Number,
            default: 100,
        },
    },
    data() {
        return {
            viewer: null,
            panorama: null,
            hotspot: require('assets/images/hotspot.png'),
            restore: require('assets/images/restore.png'),
        }
    },
    watch: {
        source() {
            if (!this.viewer) return
            if (!this.panorama) return
            this.loadPano()
        },
        replaceable() {
            if (!this.viewer) return
            if (!this.panorama) return
            this.loadPano()
        },
    },
    mounted() {
        this.viewer = new PanoLens.Viewer({
            container: document.querySelector('#panoimage'),
            // autoRotate: true,
            // autoRotateSpeed: 0.3,
            autoHideControlBar: false,
            autoRotateActivationDuration: 5000,
            cameraFov: 80,
            autoHideInfospot: false,
        })
        this.loadPano()
    },
    destroyed() {
        if (this.panorama && this.panorama.dispose) {
            this.panorama.dispose()
        }
    },
    methods: {
        loadPano() {
            if (!this.source) return
            let pano
            switch (this.type) {
                case 'video':
                    pano = new PanoLens.VideoPanorama(this.source, {
                        autoplay: true,
                    })
                    break
                default:
                    pano = new PanoLens.ImagePanorama(this.source)
                    break
            }
            if (this.panorama && this.panorama.dispose) {
                this.panorama.dispose()
            }
            this.setPano(pano)
        },
        setPano(pano) {
            if (!pano) return
            if (this.panorama) {
                this.viewer.remove(this.panorama)
            }
            this.panorama = pano
            this.viewer.add(this.panorama)
            this.viewer.setPanorama(this.panorama)
            const that = this
            this.panorama.addEventListener('load', () => {
                that.$emit('on-load')
                that.viewer.tweenControlCenter(
                    new THREE.Vector3(4871.39, 100, -18.41),
                    0,
                )
            })
            var textureLoader, tex1
            textureLoader = new THREE.TextureLoader()
            tex1 = textureLoader.load(``)
            this.replaceable.forEach((r, index) => {
                if (r.specific_data !== undefined) {
                    r.specific_data.forEach((s, index) => {
                        if (
                            s.is_pano_replaceable == true &&
                            s.is_part_of_scene == true
                        ) {
                            let infospot = new PanoLens.Infospot(
                                300,
                                `${this.hotspot}?v=${Date.now() +
                                    Math.floor(Math.random() * 100000)}`,
                            )
                            infospot.position.set(s.xloc, s.yloc, s.zloc)
                            let popover = document.getElementById(
                                `${s.id}_pano`,
                            )
                            infospot.addHoverElement(popover, 120)
                            this.panorama.add(infospot)
                            this.panorama.toggleInfospotVisibility(true)
                            if (document.getElementById(`${s.id}_pano`)) {
                                const that = this
                                document
                                    .getElementById(`${s.id}_pano_replace`)
                                    .addEventListener('click', function(e) {
                                        that.$emit('replace', e)
                                        infospot.onDismiss()
                                    })
                                document
                                    .getElementById(`${s.id}_pano_remove`)
                                    .addEventListener('click', function(e) {
                                        that.$emit('remove', e)
                                        infospot.onDismiss()
                                    })
                            }
                        } else if (
                            s.is_pano_replaceable == true &&
                            s.is_part_of_scene == false
                        ) {
                            let infospot = new PanoLens.Infospot(
                                300,
                                `${this.restore}?v=${Date.now() +
                                    Math.floor(Math.random() * 100000)}`,
                            )
                            infospot.position.set(s.xloc, s.yloc, s.zloc)
                            const that = this
                            infospot.addEventListener('click', function() {
                                that.$emit('restore', r, s)
                            })

                            this.panorama.add(infospot)
                            this.panorama.toggleInfospotVisibility(true)
                        } else {
                            return
                        }
                    })
                } else {
                    if (
                        r.is_pano_replaceable == true &&
                        r.is_duplicate == false &&
                        r.is_active == true
                    ) {
                        let infospot = new PanoLens.Infospot(
                            300,
                            `${this.hotspot}?v=${Date.now() +
                                Math.floor(Math.random() * 100000)}`,
                        )
                        infospot.position.set(
                            r.pano_xperc,
                            r.pano_yperc,
                            r.pano_zperc,
                        )
                        let popover = document.getElementById(
                            `${r.product_id}_pano`,
                        )
                        infospot.addHoverElement(popover, 120)
                        this.panorama.add(infospot)
                        this.panorama.toggleInfospotVisibility(true)
                        if (document.getElementById(`${r.product_id}_pano`)) {
                            const that = this
                            document
                                .getElementById(`${r.product_id}_pano_replace`)
                                .addEventListener('click', function(e) {
                                    that.$emit('replace', e)
                                    infospot.onDismiss()
                                })
                            document
                                .getElementById(`${r.product_id}_pano_remove`)
                                .addEventListener('click', function(e) {
                                    that.$emit('remove', e)
                                    infospot.onDismiss()
                                })
                        }
                    } else if (
                        r.is_pano_replaceable == true &&
                        r.is_duplicate == false &&
                        r.is_active == false
                    ) {
                        let infospot = new PanoLens.Infospot(
                            300,
                            `${this.restore}?v=${Date.now() +
                                Math.floor(Math.random() * 100000)}`,
                        )
                        infospot.position.set(
                            r.pano_xperc,
                            r.pano_yperc,
                            r.pano_zperc,
                        )
                        const that = this
                        infospot.addEventListener('click', function() {
                            that.$emit('restore', r)
                        })

                        this.panorama.add(infospot)
                        this.panorama.toggleInfospotVisibility(true)
                    } else {
                        return
                    }
                }
            })
        },
    },
}
</script>
<style lang="scss">
.panoimage {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
}
</style>
