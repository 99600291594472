<template>
    <transition name="removed-overlay">
        <div class="removed-overlay">
            <p>
                Moved this item to
                {{ productCategoryName }}
            </p>
            <p
                v-if="productCategoryName !== 'Included Products'"
                class="undo-action"
                @click="restoreProduct"
            >
                UNDO
            </p>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'WarningBanner',
    props: {
        productCategoryName: {
            type: String,
            default: '',
        },
    },
    methods: {
        restoreProduct() {
            this.$emit('restoreProduct')
        },
    },
}
</script>

<style lang="scss" scoped>
@import './WarningBanner.scss';
</style>
