<template>
    <div class="filters-box">
        <div class="header">
            <p>Filter</p>
            <p class="clear-all" @click="clearAllfilters">Clear All</p>
        </div>
        <div class="filters-container">
            <div v-for="filter in filters" :key="filter.filter_name">
                <FilterCheckBox
                    v-if="filter.filter_name != 'Price'"
                    :filter="filter"
                    :clearAll="clearAll"
                    @SelectedChoices="getResponse"
                    @resetClearAll="clearAll=false"
                />
            </div>
        </div>
    </div>
</template>

<script>
import FilterCheckBox from 'componentsv2/FilterCheckBox'

export default {
    name: 'FilterBox',
    components: {
        FilterCheckBox,
    },
    props: {
        filters: {
            required: true,
        },
    },
    data() {
        return {
            selectedFilters: {},
            clearAll:false,
        }
    },
    methods: {
        getResponse(data) {
            // console.log(data, 'response')
            Object.keys(data).map(key => {
                this.selectedFilters[key] = data[key]
            })
            this.$emit('filter', this.selectedFilters)
        },
        clearAllfilters(){
            this.selectedFilters={}
            this.$emit('filter', '')
            this.clearAll=true
        }
    },
}
</script>

<style lang="scss" scoped>
@import './FiltersBox.scss';
</style>
