<template>
    <div class="result-container">
        <img :src="img" />
        <div class="result-designer">
            <div class="designer-img">
                <img :src="require('@/assets/images/designer-default.png')" />
            </div>
            <div class="designer-name">
                <span>Designed by</span>
                <!-- <span v-if="data.designer_data"> -->
                <span>{{ userData.firstname }}</span>
                <!-- </span> -->
            </div>
        </div>
        <div class="result-theme">
            <h1 class="theme">Oh My, {{ userData.firstname }}</h1>
            <p class="description">
                We absolutely love what you have designed here!
            </p>
            <div class="result-button">
                <ShareNetwork
                    network="facebook"
                    :url="url"
                    :title="title"
                    :description="description"
                    hashtags="interior, designer"
                    :quote="description"
                >
                    <Button class="project" name="Share" outline />
                </ShareNetwork>
                <Button
                    class="customise"
                    name="Continue to Project"
                    primary
                    @click.native="startPQ"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Button from 'componentsv2/Button'

export default {
    name: 'PostSqCustomisation',
    components: {
        Button,
    },
    props: {
        img: {
            type: String,
        },
        url: {
            type: Object,
        },
        title: {
            type: Object,
        },
        description: {
            type: Object,
        },
    },
    data() {
        return {
            quizUrl:
                window.location.host === process.env.VUE_APP_DOMAIN_NAME
                    ? process.env.VUE_APP_QUIZ_BASE_URL_B2B
                    : process.env.VUE_APP_QUIZ_BASE_URL,
        }
    },
    created() {},
    computed: {
        ...mapState({
            userData: state => state.AuthModule.userData,
        }),
    },
    methods: {
        startPQ() {
            window.open(
                `${this.quizUrl}?startQuiz=true&survey_type=PQ&t_id=${this.$route.query.t_id}&id=${this.$route.query.id}`,
            )
        },
    },
}
</script>
<style lang="scss">
@import './PostSqCustomisation.scss';
</style>
