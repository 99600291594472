<template>
    <div class="not-found">
        <p class="not-found-heading">HMM, THIS ISN'T RIGHT</p>
        <img
            class="not-found-image"
            src="@/assets/images/no-results-found.png"
        />
        <p class="not-found-msg">{{content}} or you may have typed the link incorrectly. </p>
        <router-link to="/">
            <div class="ErrorButton">
                    <Button primary name="Go to homepage" />
            </div>
        </router-link>
    </div>
</template>
<script>
import Button from 'componentsv2/Button'
export default {
    name:'NotFound',
    components:{
        Button,
    },
    props:{
        data: String,
        content: String
    }

}
</script>
<style lang="scss" scoped>
@import './NotFound.scss';
</style>