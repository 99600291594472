<template>
    <div>
        <div v-if="highlight === 'bestseller'">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="27"
                viewBox="0 0 30 27"
            >
                <g data-name="Group 2130" transform="translate(-246 -931)">
                    <rect
                        data-name="Rectangle 2258"
                        width="30"
                        height="27"
                        rx="2"
                        transform="translate(246 931)"
                        style="fill:#f2fff3;opacity:.91"
                    />
                    <g data-name="Group 2023">
                        <path
                            data-name="Polygon 46"
                            d="M11.234.343a1 1 0 0 1 1.274.527l.917 2.065a1 1 0 0 0 .553.527l2.185.845a1 1 0 0 1 .553 1.338l-.865 1.948a1 1 0 0 0 0 .811l.865 1.948a1 1 0 0 1-.553 1.338l-2.185.845a1 1 0 0 0-.553.527l-.917 2.065a1 1 0 0 1-1.275.527l-2.3-.89a1 1 0 0 0-.721 0l-2.3.89a1 1 0 0 1-1.275-.527l-.917-2.065a1 1 0 0 0-.553-.527l-2.188-.843a1 1 0 0 1-.553-1.338l.865-1.948a1 1 0 0 0 0-.811L.426 5.646a1 1 0 0 1 .553-1.338l2.185-.845a1 1 0 0 0 .553-.527L4.635.87A1 1 0 0 1 5.909.343l2.3.89a1 1 0 0 0 .721 0z"
                            transform="translate(252.428 937)"
                            style="fill:#1ba925"
                        />
                        <g data-name="Group 1981">
                            <path
                                data-name="Polygon 45"
                                d="m4 0 1.2 2.686 2.8.37-2.058 2.03L6.472 8 4 6.569 1.528 8l.53-2.914L0 3.056l2.8-.37z"
                                transform="translate(257 941)"
                                style="fill:#fff"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <div v-if="highlight === 'recommended'">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.412"
                height="13.267"
                viewBox="0 0 18.412 13.267"
                class="recommended"
            >
                <g
                    id="noun-recommended-1397309"
                    transform="translate(-154.397 -140.221)"
                >
                    <path
                        id="Path_2714"
                        data-name="Path 2714"
                        class="cls-1"
                        d="M252.333,147.993a1.176,1.176,0,0,0-.493-.966,1.178,1.178,0,0,0,.493-.967,1.133,1.133,0,0,0-1.1-1.16H248.79a18,18,0,0,0,.647-2.652,1.91,1.91,0,0,0-.238-1.523,1,1,0,0,0-.79-.3c-.463,0-.6.552-.786,1.316-.089.362-.19.773-.34,1.215a9.785,9.785,0,0,1-.988,1.962l-.039.006a1.223,1.223,0,0,0-.983,1.037h-2.085v-.807a.194.194,0,0,0-.194-.194h-4.629a.194.194,0,1,0,0,.387H242.8V152.9h-4.435a.194.194,0,1,0,0,.387H243a.194.194,0,0,0,.194-.194v-.607h2.264a.16.16,0,0,0,.016,0,1.222,1.222,0,0,0,1.012.539h4.75a1.133,1.133,0,0,0,1.1-1.161,1.177,1.177,0,0,0-.493-.966,1.2,1.2,0,0,0,0-1.934,1.182,1.182,0,0,0,.493-.969Zm-7.074,4.107h-2.07v-5.756h2.07Zm5.973-3.333a.194.194,0,1,0,0,.387.776.776,0,0,1,0,1.547.194.194,0,1,0,0,.387.776.776,0,0,1,0,1.547h-4.75a.836.836,0,0,1-.835-.835v-5.679a.836.836,0,0,1,.682-.82.674.674,0,0,1,.087-.007.2.2,0,0,0,.153-.088,10.36,10.36,0,0,0,1.082-2.129c.156-.458.258-.878.349-1.248.123-.5.251-1.021.41-1.021a.611.611,0,0,1,.5.171,1.647,1.647,0,0,1,.146,1.217,18.746,18.746,0,0,1-.712,2.838.193.193,0,0,0,.184.253h2.705a.776.776,0,0,1,0,1.547.194.194,0,1,0,0,.387.776.776,0,0,1,0,1.547Z"
                        transform="translate(-79.724)"
                    />
                    <path
                        id="Path_2715"
                        data-name="Path 2715"
                        class="cls-1"
                        d="M202.044,258.2h3.011a.194.194,0,0,0,0-.387h-3.011a.194.194,0,0,0,0,.387Z"
                        transform="translate(-45.076 -111.98)"
                    />
                    <path
                        id="Path_2716"
                        data-name="Path 2716"
                        class="cls-1"
                        d="M212.784,291.41a.194.194,0,1,0,0,.387h2.022a.194.194,0,0,0,0-.387Z"
                        transform="translate(-55.321 -144.03)"
                    />
                    <path
                        id="Path_2717"
                        data-name="Path 2717"
                        class="cls-1"
                        d="M202.044,325.4h3.011a.194.194,0,1,0,0-.387h-3.011a.194.194,0,0,0,0,.387Z"
                        transform="translate(-45.076 -176.083)"
                    />
                    <path
                        id="Path_2718"
                        data-name="Path 2718"
                        class="cls-1"
                        d="M212.784,358.61a.194.194,0,1,0,0,.387h2.022a.194.194,0,0,0,0-.387Z"
                        transform="translate(-55.321 -208.133)"
                    />
                    <path
                        id="Path_2719"
                        data-name="Path 2719"
                        class="cls-1"
                        d="M156.134,291.41H154.79a.194.194,0,1,0,0,.387h1.344a.194.194,0,0,0,0-.387Z"
                        transform="translate(0 -144.03)"
                    />
                    <path
                        id="Path_2720"
                        data-name="Path 2720"
                        class="cls-1"
                        d="M156.134,358.61H154.79a.194.194,0,1,0,0,.387h1.344a.194.194,0,1,0,0-.387Z"
                        transform="translate(0 -208.133)"
                    />
                    <path
                        id="Path_2721"
                        data-name="Path 2721"
                        class="cls-1"
                        d="M205.254,392.4a.194.194,0,0,0-.194-.194h-3.011a.194.194,0,1,0,0,.387h3.011A.193.193,0,0,0,205.254,392.4Z"
                        transform="translate(-45.081 -240.185)"
                    />
                </g>
            </svg>
        </div>
        <div v-if="highlight === 'info'">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="info-icon"
                width="17"
                height="17"
                viewBox="0 0 17 17"
            >
                <g
                    id="Group_2146"
                    data-name="Group 2146"
                    transform="translate(-930 -971)"
                >
                    <g
                        id="Ellipse_608"
                        data-name="Ellipse 608"
                        class="cls-1"
                        transform="translate(930 971)"
                    >
                        <circle class="cls-3" cx="8.5" cy="8.5" r="8.5" />
                        <circle class="cls-4" cx="8.5" cy="8.5" r="7.85" />
                    </g>
                    <text id="i" class="cls-2" transform="translate(937 984)">
                        <tspan x="0" y="0">
                            i
                        </tspan>
                    </text>
                </g>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HighlightSVG',
    props: {
        customClass: {
            type: String,
            default: '',
        },
        highlight: {
            type: String,
            default: '',
        },
    },
    computed: {
        customclass: function() {
            return this.customClass
        },
    },
}
</script>
