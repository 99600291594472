<template>
    <div>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.867"
            height="19.867"
            viewBox="0 0 19.867 19.867"
        >
            <g data-name="Group 1593">
                <g data-name="Group 178">
                    <g data-name="Group 177">
                        <path
                            data-name="Path 559"
                            d="M9.733 0a9.733 9.733 0 1 0 9.733 9.733A9.744 9.744 0 0 0 9.733 0zm0 18.656a8.922 8.922 0 1 1 8.922-8.922 8.932 8.932 0 0 1-8.922 8.922z"
                            style="fill:#ff6100;stroke:#ff6100;stroke-width:.4px"
                            transform="translate(.2 .2)"
                        />
                        <path
                            data-name="Path 560"
                            d="m198.3 153.046-5.678-3.65a.406.406 0 0 0-.625.341v7.3a.405.405 0 0 0 .625.341l5.678-3.65a.405.405 0 0 0 0-.682zm-5.492 3.248v-5.814l4.522 2.907z"
                            transform="translate(-184.5 -143.453)"
                            style="fill:#ff6100;stroke:#ff6100;stroke-width:.4px"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'VirtualButtonSVG',
}
</script>
