<template>
    <div class="ehd-page">
        <template v-if="isLoggedIn == true">
            <template v-if="Object.keys(EHDDetails).length > 0">
                <div class="ehd-container">
                    <div class="ehd-content">
                        <div class="scene-container">
                            <div class="scene-body">
                                <div
                                    class="scene-img-wrapper"
                                    v-if="windowWidth > 1100"
                                >
                                    <img
                                        :src="
                                            default_scene_image !== ''
                                                ? default_scene_image
                                                : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                                        "
                                    />

                                    <div class="ehd-scene-button">
                                        <a
                                            :href="
                                                EHDDetails.scene_data[
                                                    currentState
                                                ].vt_link
                                            "
                                            target="_blank"
                                            v-if="
                                                EHDDetails.scene_data[
                                                    currentState
                                                ].vt_link
                                            "
                                        >
                                            <div class="virtual-button">
                                                <span class="button-text"
                                                    >Virtual Tour</span
                                                >
                                                <VirtualButtonSVG />
                                            </div>
                                        </a>
                                        <template
                                            v-if="
                                                scene_thumbnails[currentState]
                                                    .length > 1
                                            "
                                        >
                                            <div
                                                class="image-button"
                                                @click.stop="
                                                    toggleSceneThumbnails
                                                "
                                                v-if="!showThumbnails"
                                                tabindex="0"
                                                @focus="hideThumbnails(false)"
                                                @blur="hideThumbnails(true)"
                                            >
                                                <span class="button-text"
                                                    >More Image</span
                                                >
                                                <EhdMoreImageButtonSVG />
                                            </div>
                                            <div
                                                class="nocursor-view"
                                                v-else
                                                tabindex="0"
                                                @focus="hideThumbnails(false)"
                                                @blur="hideThumbnails(true)"
                                            >
                                                <span class="more-text"
                                                    >More Image</span
                                                >
                                                <EhdMoreImageButtonSVG />
                                                <div class="dropdown_menu">
                                                    <div
                                                        class="scrollable-menu"
                                                    >
                                                        <div
                                                            v-for="(image,
                                                            i) in scene_thumbnails[
                                                                currentState
                                                            ]"
                                                            :key="i"
                                                            class="thumbnail-container"
                                                            @click="
                                                                changeDefaultSceneImage(
                                                                    image.image,
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                class="thumbnail-image"
                                                            >
                                                                <img
                                                                    :src="
                                                                        image.image
                                                                    "
                                                                    :class="
                                                                        default_scene_image ==
                                                                        image.image
                                                                            ? 'activeThumbnail'
                                                                            : ''
                                                                    "
                                                                />
                                                            </div>
                                                            <p>
                                                                {{
                                                                    image.scene_image_type
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <CaretSVG
                                                        direction="reverse"
                                                    />
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <button
                                        class="ehd-full-screen"
                                        :href="
                                            default_scene_image !== ''
                                                ? default_scene_image
                                                : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                                        "
                                        target="_blank"
                                        @click="showFullEhdModal(true)"
                                    >
                                        <EhdFullScreenSVG />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Tabs
                            :key="EHDDetails.id"
                            :isselected="currentState"
                            @selectedTab="updateCurrentState"
                            :ehd="true"
                            class="scene-tabs"
                            :carousel="true"
                        >
                            <WarningBanner
                                v-if="showRemovedOverlay"
                                :productCategoryName="productCategoryName"
                                @restoreProduct="restoreProduct"
                            />
                            <Tab
                                v-for="(scene, index) in EHDDetails.scene_data"
                                :key="`${scene.id}_${index}`"
                                :title="scene.room_type"
                                :img="
                                    scene.scene_image_data.length > 0
                                        ? scene.scene_image_data[0]
                                              .scene_image_url
                                        : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                                "
                                :blur="!roomsAvailable[index].room_available"
                            >
                                <div class="scene-details">
                                    <div class="search-and-view">
                                        <SearchAutoComplete
                                            @search="
                                                fetchSearchedProductsOrServices
                                            "
                                            @clear="clearSearchedProducts()"
                                            class="search-complete"
                                        >
                                        </SearchAutoComplete>
                                        <div
                                            :class="
                                                currentTabTitle !=
                                                    'Included Services' &&
                                                currentTabTitle !=
                                                    'Custom Products' &&
                                                currentTabTitle != 'Services' &&
                                                currentTab != -1
                                                    ? 'viewby-category'
                                                    : 'viewby-single'
                                            "
                                        >
                                            <div
                                                class="category-list"
                                                @click="
                                                    displayViewByToggle(false)
                                                "
                                            >
                                                <CategoryListViewSVG
                                                    :fill="
                                                        displayView
                                                            ? '#bbbbbb'
                                                            : '#ff6100'
                                                    "
                                                />
                                            </div>
                                            <div
                                                v-if="
                                                    currentTabTitle !=
                                                        'Included Services' &&
                                                        currentTabTitle !=
                                                            'Services' &&
                                                        currentTab != -1 &&
                                                        currentTabTitle !=
                                                            'Custom Products'
                                                "
                                                class="category-thumb"
                                                @click="
                                                    displayViewByToggle(true)
                                                "
                                            >
                                                <CategoryListViewSVG
                                                    view="thumb"
                                                    :customClass="
                                                        displayView
                                                            ? 'cls-1'
                                                            : ''
                                                    "
                                                    :fill="
                                                        displayView
                                                            ? '#ff6100'
                                                            : '#bbbbbb'
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Tabs
                                        :isselected="currentTab"
                                        @selectedTab="updateCurrentTab"
                                        @seletedTabTitle="updateCurrentTabTitle"
                                        :verticalTabs="true"
                                        class="ehd-tab-data"
                                    >
                                        <div
                                            v-if="
                                                queryString !== '' &&
                                                    searchedProducts.responseCode ===
                                                        200
                                            "
                                            class="products-active"
                                            style="width:100%;"
                                        >
                                            <template
                                                v-if="addProducts === true"
                                            >
                                                <div class="add-more-container">
                                                    <p
                                                        class="add-product-header"
                                                    >
                                                        No Product Found
                                                    </p>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <List
                                                    type="products"
                                                    :obj="{ view: false }"
                                                    :List="
                                                        searchedProducts.payload
                                                            .base_products
                                                            .length > 0
                                                            ? searchedProducts
                                                                  .payload
                                                                  .base_products
                                                            : searchedProducts
                                                                  .payload
                                                                  .products
                                                                  .length > 0
                                                            ? searchedProducts
                                                                  .payload
                                                                  .products
                                                            : []
                                                    "
                                                    :scene_id="scene.id"
                                                    :ehd_id="EHDDetails.id"
                                                    :requiredDomain="
                                                        requiredDomain
                                                    "
                                                    @checkDraft="checkDraft"
                                                />
                                            </template>
                                            <p
                                                @click="
                                                    checkDraft({
                                                        ehd_id: EHDDetails.id,
                                                        scene_id: scene.id,
                                                        operation: 'add',
                                                        scene_type:
                                                            scene.scene_type,
                                                    })
                                                "
                                                class="add-prod-button"
                                            >
                                                + Add New Product
                                            </p>
                                        </div>

                                        <Tab
                                            :title="`Included Products`"
                                            :verticalTab="true"
                                        >
                                            <div
                                                v-if="
                                                    roomData[currentState]
                                                        .products.length > 0 ||
                                                        roomData[currentState]
                                                            .customProducts
                                                            .length > 0
                                                "
                                                class="products-active"
                                            >
                                                <List
                                                    type="Products"
                                                    :obj="{
                                                        view: displayView,
                                                    }"
                                                    :List="
                                                        roomData[
                                                            currentState
                                                        ].products.concat(
                                                            roomData[
                                                                currentState
                                                            ].customProducts,
                                                        )
                                                    "
                                                    :scene_id="scene.id"
                                                    :ehd_id="EHDDetails.id"
                                                    :requiredDomain="
                                                        requiredDomain
                                                    "
                                                    @checkDraft="checkDraft"
                                                />
                                            </div>
                                            <div
                                                v-else
                                                class="no_active_product"
                                            >
                                                <p
                                                    class="no_active_product_content"
                                                >
                                                    No active products
                                                </p>
                                            </div>
                                        </Tab>
                                        <Tab
                                            :title="`Included Services`"
                                            :verticalTab="true"
                                        >
                                            <div
                                                v-if="
                                                    roomData[currentState]
                                                        .services.length > 0
                                                "
                                                class="services-active"
                                            >
                                                <List
                                                    type="Services"
                                                    :obj="{
                                                        active: true,
                                                    }"
                                                    :List="
                                                        roomData[currentState]
                                                            .services
                                                    "
                                                    :scene_id="scene.id"
                                                    :ehd_id="EHDDetails.id"
                                                    :requiredDomain="
                                                        requiredDomain
                                                    "
                                                    @checkDraft="checkDraft"
                                                />
                                            </div>
                                            <div
                                                v-else
                                                class="no_active_product"
                                            >
                                                <p
                                                    class="no_active_product_content"
                                                >
                                                    No active services
                                                    available.
                                                </p>
                                            </div>
                                        </Tab>

                                        <Tab
                                            v-for="(data, index) in roomData[
                                                currentState
                                            ].categoryGroups"
                                            :title="data"
                                            :verticalTab="true"
                                            :key="index"
                                        >
                                            <div class="products-active">
                                                <p
                                                    class="add-all-products"
                                                    @click="
                                                        checkDraft({
                                                            product:
                                                                removedProduct[
                                                                    data
                                                                ][0],
                                                            scene_id: scene.id,
                                                            operation: 'addAll',
                                                            enable: true,
                                                            ehd_id:
                                                                EHDDetails.id,
                                                            category: data,
                                                        })
                                                    "
                                                >
                                                    + Add all
                                                    {{ data }} Products
                                                </p>
                                                <List
                                                    type="Products"
                                                    :obj="{
                                                        view: displayView,
                                                    }"
                                                    :List="removedProduct[data]"
                                                    :scene_id="scene.id"
                                                    :ehd_id="EHDDetails.id"
                                                    :requiredDomain="
                                                        requiredDomain
                                                    "
                                                    @checkDraft="checkDraft"
                                                />
                                            </div>
                                        </Tab>

                                        <Tab
                                            :title="`Services`"
                                            :verticalTab="true"
                                            v-if="
                                                roomData[currentState]
                                                    .removedServices.length > 0
                                            "
                                        >
                                            <div class="services-active">
                                                <List
                                                    type="Services"
                                                    :obj="{
                                                        active: false,
                                                    }"
                                                    :List="
                                                        roomData[currentState]
                                                            .removedServices
                                                    "
                                                    :scene_id="scene.id"
                                                    :ehd_id="EHDDetails.id"
                                                    :requiredDomain="
                                                        requiredDomain
                                                    "
                                                    @checkDraft="checkDraft"
                                                />
                                            </div>
                                        </Tab>
                                        <Tab
                                            :title="`Custom Products`"
                                            :verticalTab="true"
                                            v-if="
                                                roomData[currentState]
                                                    .removedCustomProducts
                                                    .length > 0
                                            "
                                            class="services-active"
                                        >
                                            <div class="products-active">
                                                <List
                                                    type="Products"
                                                    :obj="{
                                                        view: false,
                                                    }"
                                                    :List="
                                                        roomData[currentState]
                                                            .removedCustomProducts
                                                    "
                                                    :scene_id="scene.id"
                                                    :ehd_id="EHDDetails.id"
                                                    :requiredDomain="
                                                        requiredDomain
                                                    "
                                                    @checkDraft="checkDraft"
                                                />
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    <div class="ehd-details">
                        <div class="finalise-div">
                            <p>
                                Total Cost:
                                <span v-if="$route.query.ok">
                                    ₹
                                    {{
                                        convertToIndianNumberSystem(
                                            kitchenPrice,
                                        )
                                    }}
                                </span>
                                <span v-else>
                                    ₹
                                    {{
                                        convertToIndianNumberSystem(
                                            EHDDetails.total_cost,
                                        )
                                    }}
                                </span>
                            </p>
                            <p>Estimated Completion 4 weeks</p>
                            <router-link
                                :to="{
                                    name: 'FinaliseAndPlaceOrderPage',
                                    query: {
                                        ehd_id: EHDDetails.id,
                                        scene_id: EHDDetails.scene_data[0].id,
                                    },
                                }"
                            >
                                <Button
                                    name="Finalize"
                                    primary
                                    class="finalise-button"
                                />
                            </router-link>
                        </div>
                        <img
                            :src="
                                EHDDetails.designer_image
                                    ? EHDDetails.designer_image
                                    : require('assets/images/default-designer.png')
                            "
                            :alt="EHDDetails.designer"
                            class="designer-image"
                        />
                        <p class="detail-text">
                            Designed by {{ EHDDetails.designer }}
                        </p>
                        <p class="detail-text">
                            Project Name: {{ EHDDetails.project_name }}
                        </p>
                        <p class="detail-text">
                            Layout Type: {{ EHDDetails.floor_plan
                            }}<span v-if="EHDDetails.carpet_area"
                                >({{ EHDDetails.carpet_area }})</span
                            >
                        </p>
                        <p class="detail-text">
                            {{ EHDDetails.scene_data[currentState].room_type }}
                            | ₹{{
                                convertToIndianNumberSystem(
                                    EHDDetails.scene_data[currentState].price,
                                )
                            }}
                            |
                            {{ EHDDetails.theme }}
                        </p>

                        <div class="total-price">
                            <p>
                                Included Products({{
                                    EHDDetails.scene_data[currentState]
                                        .base_products.length
                                }})<span
                                    >₹{{
                                        convertToIndianNumberSystem(
                                            EHDDetails.scene_data[currentState]
                                                .price_of_products,
                                        )
                                    }}</span
                                >
                            </p>
                            <p>
                                Included Services ({{
                                    EHDDetails.scene_data[currentState]
                                        .base_services.length
                                }})<span
                                    >₹{{
                                        convertToIndianNumberSystem(
                                            EHDDetails.scene_data[currentState]
                                                .price_of_services,
                                        )
                                    }}</span
                                >
                            </p>
                            <p class="total-cost">
                                Total Cost<span
                                    >₹{{
                                        convertToIndianNumberSystem(
                                            EHDDetails.scene_data[currentState]
                                                .price,
                                        )
                                    }}</span
                                >
                            </p>
                        </div>

                        <Button
                            v-if="
                                EHDDetails.scene_data[currentState]
                                    .scene_type == 'WHITE'
                            "
                            name="customise"
                            orange
                            class="customisation-button"
                            :data-scene-id="
                                EHDDetails.scene_data[currentState].id
                            "
                            @click.native="
                                checkDraft({
                                    ehd_id: EHDDetails.id,
                                    scene_id:
                                        EHDDetails.scene_data[currentState].id,
                                    operation: 'customise',
                                    scene:
                                        EHDDetails.scene_data[currentState]
                                            .room_type,
                                    scene_img:
                                        EHDDetails.scene_data[currentState]
                                            .scene_image_data[0].length != 0
                                            ? EHDDetails.scene_data[
                                                  currentState
                                              ].scene_image_data[0]
                                                  .scene_image_url
                                            : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg',
                                })
                            "
                        />
                    </div>
                </div>
                <Modal
                    v-if="showDescription"
                    @close="showDescription = false"
                    transparent
                >
                    <div class="service-description">
                        <p>Description</p>
                        {{ serviceDescription }}
                    </div>
                </Modal>
                <Modal
                    v-if="showRestoreModal"
                    @close=";(showRestoreModal = false), (countOfProduct = 1)"
                    transparent
                >
                    <div class="removed-products-popup">
                        <p>
                            {{ draftObject.product.name.slice(0, 50)
                            }}<span v-if="draftObject.product.name.length > 50"
                                >...</span
                            >
                        </p>
                        <div class="number-of-products">
                            <div
                                class="remove-one"
                                v-if="countOfProduct != 1"
                                @click="countOfProduct--"
                            >
                                <OperationsSVG operation="minus" />
                            </div>
                            <div v-else class="disable-icon">
                                <OperationsSVG operation="minus" />
                            </div>

                            <div class="total">
                                {{ countOfProduct }}
                            </div>

                            <div class="add-one" @click="countOfProduct++">
                                <OperationsSVG operation="plus" />
                            </div>
                        </div>
                        <div
                            class="add-button"
                            @click="
                                restoreMultipleProducts({
                                    product: draftObject.product,
                                    scene_id: draftObject.scene_id,
                                    operation: 'addMultiple',
                                    enable: true,
                                    ehd_id: draftObject.ehd_id,
                                    quantity: countOfProduct,
                                })
                            "
                        >
                            + ADD
                        </div>
                    </div>
                </Modal>
            </template>

            <template v-if="showNotFound == true">
                <NotFound :data="'EHD'" :content="content" />
            </template>
            <FullPageNavModal
                v-if="showModal"
                @close="toggleFullPageModal"
                :sceneId="currentSceneId"
                :ehdId="ehdId"
                :theme="EHDDetails.theme"
                :requiredDomain="requiredDomain"
                @update="updateEHD"
            >
            </FullPageNavModal>

            <!-- <ReplaceProductModal
                v-if="replaceProductModal"
                :currentProductID="productID"
                :currentProductImage="
                    productImage
                        ? productImage
                        : require('@/assets/images/default-product-img.png')
                "
                :currentProductName="productName"
                :requiredDomain="requiredDomain"
                :currentProductPrice="productPrice"
                :currentProductShow="currentProductShow"
                :currentProductDescription="productDescription"
                :currentSceneId="currentSceneId"
                :add="add"
                @setLoader="setLoader"
                @toggleReplaceProductModal="toggleReplaceProductModal"
                @changeProductForEHD="changeProductForEHD"
                @addProductForEHD="addProductForEHD"
            /> -->
            <FullPageModal
                v-if="replaceProductModal == true"
                @close="toggleReplaceProductModal"
                @loadMoreProducts="loadMoreProducts"
                :replaceProduct="true"
                class="replace-product-modal"
            >
                <div class="productlist-layout">
                    <div class="sidebar">
                        <div class="sidebar-item">
                            <div
                                class="current-product"
                                v-if="currentProductShow == true"
                            >
                                <router-link
                                    :to="{
                                        name: 'ProductDetailsPage',
                                        params: {
                                            id: productID,
                                        },
                                    }"
                                >
                                    <CurrentProduct
                                        :img="
                                            productImage
                                                ? productImage
                                                : require('@/assets/images/default-product-img.png')
                                        "
                                        :productName="productName"
                                        :requiredDomain="requiredDomain"
                                        :productPrice="productPrice"
                                        :description="productDescription"
                                    />
                                </router-link>
                            </div>

                            <div
                                class="filters"
                                v-if="
                                    add == false &&
                                        filters[1].options.length != 0
                                "
                            >
                                <FiltersBox
                                    :filters="filters"
                                    @filter="getFilteredProduct"
                                    v-if="windowWidth > 1100"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="main">
                        <template v-if="relatedProducts">
                            <div class="productlist-head">
                                <h1 class="">
                                    {{
                                        add == true
                                            ? 'Add product'
                                            : 'Replace with'
                                    }}
                                </h1>

                                <SearchAutoComplete
                                    @search="fetchSuggestions"
                                    @loadMoreSearchOptions="
                                        loadMoreSearchOptions
                                    "
                                    :suggestionsArr="suggestions"
                                >
                                    <template #suggestion="{suggestion}">
                                        <div class="autocomplete-product">
                                            <div
                                                class="autocomplete-product-img"
                                            >
                                                <img :src="suggestion.image" />
                                            </div>
                                            <div
                                                class="autocomplete-product-detail"
                                            >
                                                <p>{{ suggestion.name }}</p>
                                                <div
                                                    class="autocomplete-category"
                                                >
                                                    <span>
                                                        {{
                                                            suggestion.item_category
                                                        }}
                                                    </span>
                                                    <template
                                                        v-if="
                                                            suggestion.item_subcategory
                                                        "
                                                    >
                                                        <span>
                                                            <CaretSVG
                                                                direction="right"
                                                            />
                                                        </span>
                                                        <span>
                                                            {{
                                                                suggestion.item_subcategory
                                                            }}
                                                        </span>
                                                    </template>
                                                </div>
                                            </div>
                                            <div
                                                class="autocomplete-product-action"
                                            >
                                                <template v-if="add == true">
                                                    <Button
                                                        class="autocomplete-add"
                                                        name="Add"
                                                        primary
                                                        @click.native="
                                                            addProductForEHD(
                                                                suggestion,
                                                            )
                                                        "
                                                    />
                                                </template>
                                                <template v-else>
                                                    <Button
                                                        class="autocomplete-add"
                                                        name="Replace"
                                                        primary
                                                        @click.native="
                                                            changeProductForEHD(
                                                                suggestion,
                                                            )
                                                        "
                                                    />
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                </SearchAutoComplete>
                            </div>
                            <div class="cards-container">
                                <template
                                    v-if="relatedProducts.payload.prod_data"
                                >
                                    <div
                                        v-for="product in relatedProducts
                                            .payload.prod_data"
                                        :key="product.id"
                                    >
                                        <ProductCards
                                            v-if="add === true"
                                            :threeDavailable="
                                                product.is_3d_available
                                            "
                                            :product-obj="product"
                                            :vendor="product.vendor"
                                            :dimRemarks="product.dim_remarks"
                                            :product-id="product.product_id"
                                            :img="
                                                product.image_meta
                                                    ? product.image_meta.n_image
                                                    : require('@/assets/images/default-product-img.png')
                                            "
                                            :name="product.name"
                                            :current-price="product.item_price"
                                            :requiredDomain="requiredDomain"
                                            :bestSeller="product.bestseller"
                                            :recommended="product.recommended"
                                            @add="addProductForEHD(product)"
                                        />
                                    </div>
                                </template>
                                <template v-else>
                                    <div
                                        v-for="product in similarProducts"
                                        :key="product.id"
                                    >
                                        <ProductCards
                                            :threeDavailable="
                                                product.is_3d_available
                                            "
                                            :product-obj="product"
                                            replace
                                            :vendor="product.vendor"
                                            :dimRemarks="product.dim_remarks"
                                            :product-id="product.product_id"
                                            :img="
                                                product.image_meta
                                                    ? product.image_meta.n_image
                                                    : require('@/assets/images/default-product-img.png')
                                            "
                                            :name="product.name"
                                            :current-price="product.item_price"
                                            :requiredDomain="requiredDomain"
                                            :bestSeller="product.bestseller"
                                            :recommended="product.recommended"
                                            @replace="
                                                changeProductForEHD(product)
                                            "
                                        />
                                    </div>
                                </template>
                            </div>
                            <div class="loader-container"></div>
                        </template>
                        <template v-else>
                            <div
                                style="text-align: center;"
                                class="no-products-found"
                            >
                                <img
                                    src="@/assets/images/no-results-found.png"
                                />
                                <p>No Products Available</p>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="request-product-container">
                    <RequestProduct />
                </div>
            </FullPageModal>

            <!-- <Modal v-if="ehdShowModal" hideClose transparent>
                <div class="ehdmodal">
                    <div class="ehdmodal-view">
                        <p>{{ ehdModalMsg }}</p>
                    </div>
                </div>
            </Modal> -->
            <Modal v-if="draftModal" @close="closeDraftModal" transparent>
                <div class="ehdmodal">
                    <div class="ehdmodal-view">
                        <p>{{ draftModalMsg }}</p>

                        <div
                            class="ehd-display"
                            v-if="showDraftVersions == true"
                        >
                            <template v-if="useWorkspaceComp == false">
                                <div class="draft-ehd">
                                    <p>My Draft</p>
                                    <div class="draft-details">
                                        <img
                                            :src="
                                                draftDetails.draft_scene
                                                    .scene_image
                                            "
                                        />
                                        <div class="draft-info">
                                            <p
                                                :class="
                                                    requiredDomain == false
                                                        ? 'blur'
                                                        : ''
                                                "
                                            >
                                                ₹{{
                                                    convertToIndianNumberSystem(
                                                        draftDetails.draft_scene
                                                            .price,
                                                    )
                                                }}
                                            </p>
                                            <p>
                                                {{
                                                    draftDetails.draft_scene
                                                        .no_of_products
                                                }}
                                                Products
                                            </p>
                                            <p>
                                                {{
                                                    draftDetails.draft_scene
                                                        .no_of_services
                                                }}
                                                Services
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="original-ehd">
                                    <p>Original</p>
                                    <div class="draft-details">
                                        <img
                                            :src="
                                                draftDetails.original_scene
                                                    .scene_image
                                            "
                                        />
                                        <div class="draft-info">
                                            <p
                                                :class="
                                                    requiredDomain == false
                                                        ? 'blur'
                                                        : ''
                                                "
                                            >
                                                ₹{{
                                                    convertToIndianNumberSystem(
                                                        draftDetails
                                                            .original_scene
                                                            .price,
                                                    )
                                                }}
                                            </p>
                                            <p>
                                                {{
                                                    draftDetails.original_scene
                                                        .no_of_products
                                                }}
                                                Products
                                            </p>
                                            <p>
                                                {{
                                                    draftDetails.original_scene
                                                        .no_of_services
                                                }}
                                                Services
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-else>
                                <div>
                                    <WorkspaceComponent
                                        :draftImage="
                                            draftDetails.draft_scene.scene_image
                                        "
                                        :theme="
                                            draftDetails.draft_scene.theme_name
                                        "
                                        :ogDetails="draftDetails.original_scene"
                                        :price="draftDetails.draft_scene.price"
                                        :footerName="
                                            draftDetails.draft_scene
                                                .display_name
                                        "
                                        :noOfProducts="
                                            draftDetails.draft_scene
                                                .no_of_products
                                        "
                                        :noOfServices="
                                            draftDetails.draft_scene
                                                .no_of_services
                                        "
                                        :space="
                                            draftDetails.draft_scene.space_name
                                        "
                                        :eta="draftDetails.draft_scene.ETA"
                                        :products="
                                            draftDetails.draft_scene
                                                .no_of_products
                                        "
                                        :timeCreated="
                                            draftDetails.draft_scene.updated_at
                                        "
                                        :sceneid="
                                            draftDetails.draft_scene.scene_id
                                        "
                                        :ehdid="
                                            draftDetails.draft_scene
                                                .draft_ehd_id
                                                ? draftDetails.draft_scene
                                                      .draft_ehd_id
                                                : null
                                        "
                                        :draftBySpace="true"
                                        :hideButtons="true"
                                    />
                                </div>
                            </template>
                        </div>

                        <div
                            class="result-button"
                            v-if="showDraftVersions == true"
                            style="margin-top: 20px; display: flex; justify-content: center;"
                        >
                            <Button
                                class="delete-draft"
                                name="Continue"
                                outline
                                @click.native="continueWithDraft"
                            />
                            <Button
                                style="margin-left:20px;"
                                class="override"
                                name="Restore"
                                primary
                                @click.native="restoreDraft"
                            />
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                v-if="showEhdModal"
                customClass="scene-image-modal"
                @close="showFullEhdModal"
                transparent
            >
                <img
                    class="fimage"
                    :src="
                        default_scene_image !== ''
                            ? default_scene_image
                            : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
                    "
                />
            </Modal>

            <template
                v-if="
                    loaderShow ||
                        (Object.keys(EHDDetails).length == 0 && !showNotFound)
                "
            >
                <div class="wait">
                    <Loader :loaderType="loaderType" />
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { getDraft, deleteDraft, updateDraft } from 'api/userApi'
import {
    toggleProduct,
    // removeProduct,
    // toggleService,
    changeProduct as changeProductApi,
    // addProduct,
    getProductsBySearch,
    customise_scene,
} from 'api/productsApi'
import CustomisationPageModule from 'store/modules/CustomisationPage'
import ProductListPageModule from 'store/modules/ProductListPage'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import ConvertToPrice from 'mixins/ConvertToPrice'
import EHDPageModule from 'store/modules/EHDPage'
import AuthModule from 'store/modules/$authModule'
import Button from 'componentsv2/Button'
import Tabs from 'componentsv2/Tabs'
import NotFound from 'componentsv2/NotFound'
import Tab from 'componentsv2/Tab'
//import Cards from 'componentsv2/Cards'
import NewCard from 'componentsv2/NewCard'
import FullPageNavModal from 'componentsv3/FullPageNavModal'
import VirtualButtonSVG from 'componentsv3/SVG/VirtualButtonSVG'
import EhdMoreImageButtonSVG from 'componentsv3/SVG/EhdMoreImageButtonSVG'
import CaretSVG from 'componentsv3/SVG/CaretSVG'
import EhdFullScreenSVG from 'componentsv3/SVG/EhdFullScreenSVG'
import CategoryListViewSVG from 'componentsv3/SVG/CategoryListViewSVG'
import OperationsSVG from 'componentsv3/SVG/OperationsSVG'
import List from 'componentsv3/List'
import WarningBanner from 'componentsv3/WarningBanner'
import Modal from 'componentsv2/Modal'
import Loader from 'componentsv2/Loader'
import FullPageModal from 'componentsv2/FullPageModal'
import CurrentProduct from 'componentsv2/CurrentProduct'
import ProductCards from 'componentsv2/ProductCards'
import FiltersBox from 'componentsv2/FiltersBox'
import RequestProduct from 'componentsv2/RequestProduct'
import SearchAutoComplete from 'componentsv2/SearchAutoComplete'
import CustomizeKitchenModule from 'store/modules/CustomizeKitchenPage'
import { getEHDDetailsv3, submitKitchenData } from 'api/ehdApi'
import { toggleOnlyKitchen } from 'api/userApi'
import Carousel from 'componentsv2/Carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import WorkspaceComponent from 'componentsv2/WorkspaceComponent'
import loaderHandler from 'mixins/loader'
// import ReplaceProductModal from 'componentsv2/ReplaceProductModal'

export default {
    name: 'EHDPageRefactor',
    components: {
        Button,
        Tabs,
        Tab,
        //Cards,
        FullPageNavModal,
        Modal,
        Loader,
        // NewCard,
        NotFound,
        FullPageModal,
        VirtualButtonSVG,
        EhdMoreImageButtonSVG,
        CaretSVG,
        EhdFullScreenSVG,
        CategoryListViewSVG,
        OperationsSVG,
        CurrentProduct,
        ProductCards,
        FiltersBox,
        RequestProduct,
        SearchAutoComplete,
        List,
        WarningBanner,
        // ReplaceProductModal,
        // Carousel,
        WorkspaceComponent,
    },
    mixins: [RegisterStoreModule, ConvertToPrice, loaderHandler],
    props: {
        requiredDomain: Boolean,
    },
    data() {
        return {
            settings1: {
                dots: true,
                dragabble: true,
                arrows: false,
                focusOnSelect: true,
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
            showNotFound: false,
            // loaderShow: false,
            showModal: false,
            callDraft: false,
            draftModal: false,
            draftModalMsg: '',
            ehdId: this.$route.params.id ? this.$route.params.id : null,
            currentSceneId: null,
            draftEHDId: null,
            draftSceneId: null,
            preEHDShow: this.$route.query.pq ? this.$route.query.pq : false,
            seeMoreActivated: false,
            scrollPosition: 0,
            showMenu: null,
            replaceProductModal: false,
            productID: null,
            productIndex: null,
            newSceneId: null,
            productImage: null,
            productName: null,
            productPrice: null,
            productDescription: null,
            currentSceneImageIndex: 0,
            draftExists: null,
            quantity: null,
            countAltered: false,
            currentProductShow: true,
            scene_type: '',
            scene_id: null,
            add: false,
            autocomplete: '',
            suggestions: [],
            currentState: 0,
            currentTab: 0,
            //paging for related products
            pageNumber: 1,
            totalNumberOfPages: '',
            similarProducts: null,
            autoLoad: true,
            filter_str: '',
            default_scene_image: '',
            scene_thumbnails: [],
            showThumbnails: false,
            //paging for search products
            searchNumber: 1,
            totalNumberOfSearchPages: '',
            searchingActive: true,
            //Checking which operation called while checking draft
            operation: '',
            draftObject: {},
            showDraftVersions: false,
            useWorkspaceComp: false,
            draftDetails: {},
            checkDraftExists: false,
            content: 'Not allowed to access this EHD,',
            roomsAvailable: [],
            kitchenPrice: 0,
            displayView: false,
            sceneData: [],
            serviceDescription: '',
            showDescription: false,
            queryString: '',
            addProducts: false,
            showRemovedOverlay: false,
            showRestoreModal: false,
            countOfProduct: 1,
            productCategoryName: 'none',
            currentTabTitle: '',
            showEhdModal: false,
            productInfo: {},
        }
    },
    computed: {
        ...mapState({
            isLoggedIn: state => state.AuthModule.isLoggedIn,
            userData: state => state.AuthModule.userData,
            showAuthModal: state => state.AuthModule.showAuthModal,
            EHDDetails: state => state.EHDPage.EHDDetails,
            ehdShowModal: state => state.EHDPage.ehdShowModal,
            ehdModalMsg: state => state.EHDPage.ehdModalMsg,
            windowWidth: state => state.AppModule.windowWidth,
            filters: state => state.ProductListPage.filters_data,
            allSceneData: state => state.CustomisationPage.scene,
            scene: state => state.CustomisationPage.scene.scene_dsetTimeoutata,
            relatedProducts: state => state.CustomisationPage.relatedProducts,
            searchedProducts: state => state.CustomisationPage.searchedProducts,
            totalProducts: state => state.EHDPage.totalProducts,
            totalServices: state => state.EHDPage.totalServices,
            totalCustomProducts: state => state.EHDPage.totalCustomProducts,
            baseProducts: state => state.EHDPage.baseProducts,
            products: state => state.EHDPage.products,
            categoryGroups: state => state.EHDPage.categoryGroups,
            roomData: state => state.EHDPage.roomData,
        }),
        removedProduct() {
            return this.roomData[this.currentState].removedProducts
        },
    },
    watch: {
        isLoggedIn(e) {
            this.fetchEHDDetailsv3(this.$route.params.id)
        },
        showRemovedOverlay(e) {
            if (e === true) {
                setTimeout(() => {
                    this.showRemovedOverlay = false
                }, 6000)
            }
        },
        $route(to, from) {
            if (to !== from) {
                this.fetchEHDDetailsv3(this.$route.params.id).then(payload => {
                    if (payload.responseCode === 200) {
                        this.setDefaultThumbnails(payload.data.scene_data)
                        this.loaderShow = false
                        if (this.$route.query.scene) {
                            payload.data.scene_data.forEach((scene, index) => {
                                if (scene.id === this.$route.query.scene) {
                                    this.currentState = index
                                }
                            })
                        }
                        if (this.$route.query.ok) {
                            payload.data.scene_data.forEach((scene, index) => {
                                let obj = {
                                    room_available: '',
                                    scene: scene.id,
                                }
                                if (scene.room_type === 'Kitchen') {
                                    obj.room_available = true
                                    this.currentState = index
                                    this.kitchenPrice = scene.price
                                } else {
                                    obj.room_available = false
                                }
                                this.roomsAvailable.push(obj)
                            })
                        } else {
                            payload.data.scene_data.forEach((scene, index) => {
                                let obj = {
                                    room_available: true,
                                    scene: scene.id,
                                }
                                this.roomsAvailable.push(obj)
                            })
                        }
                    } else {
                        this.loaderShow = false
                        this.showNotFound = true
                    }
                })
            }
        },
    },
    metaInfo: {
        title: 'SILOHO',
        titleTemplate: '%s | EHD Page',
        bodyAttrs: {
            class: ['ehd-page-body'],
        },
    },
    created() {
        this.registerStoreModule('EHDPage', EHDPageModule)
        this.registerStoreModule('CustomizeKitchenPage', CustomizeKitchenModule)
        // this.loaderShow = true

        this.registerStoreModule('CustomisationPage', CustomisationPageModule)
        this.registerStoreModule('ProductListPage', ProductListPageModule)
        this.fetchEHDDetailsv3(this.$route.params.id).then(payload => {
            if (payload.responseCode === 200) {
                this.loaderShow = false
                this.setDefaultThumbnails(payload.data.scene_data)

                if (this.$route.query.scene) {
                    payload.data.scene_data.forEach((scene, index) => {
                        if (scene.id === this.$route.query.scene) {
                            this.currentState = index
                        }
                    })
                }

                if (this.$route.query.ok) {
                    payload.data.scene_data.forEach((scene, index) => {
                        let obj = { room_available: '', scene: scene.id }
                        if (scene.room_type === 'Kitchen') {
                            obj.room_available = true
                            this.currentState = index
                            this.kitchenPrice = scene.price
                        } else {
                            obj.room_available = false
                        }
                        this.roomsAvailable.push(obj)
                    })
                } else {
                    payload.data.scene_data.forEach((scene, index) => {
                        let obj = { room_available: true, scene: scene.id }
                        this.roomsAvailable.push(obj)
                    })
                }
            } else {
                this.loaderShow = false
                this.showNotFound = true
            }
        })
    },
    mounted() {
        if (this.$route.query.scene_id) {
            this.checkDraft(this.$route.query.scene_id)
        }
    },
    methods: {
        ...mapActions({
            fetchEHDDetailsv3: 'fetchEHDDetailsv3',
            toggleEhdModal: 'toggleEhdModal',
            toggleShowAuthModal: 'toggleShowAuthModal',
            fetchFilters: 'fetchFilters',
            fetchScene: 'fetchScene',
            fetchRelatedProducts: 'fetchRelatedProducts',
            fetchSearchedProducts: 'fetchSearchedProducts',
            fetchProducts: 'fetchProducts',
            updateRelatedProducts: 'updateRelatedProducts',
            updateSearchedProducts: 'updateSearchedProducts',
            CLIENT_SCENE: 'CLIENT_SCENE',
            restoreDraftDetailsv3: 'restoreDraftDetailsv3',
            fetchQuoteList: 'fetchQuoteList',
            changeBaseProductCount: 'changeBaseProductCount',
            removeProduct: 'removeProduct',
            toggleCustomProduct: 'toggleCustomProduct',
            toggleService: 'toggleService',
            addProduct: 'addProduct',
            replaceBaseProduct: 'replaceBaseProduct',
        }),
        showFullEhdModal(val) {
            this.showEhdModal = val
            document.querySelector('body').style.overflow = this.showEhdModal
                ? 'hidden'
                : 'auto'
        },
        displayViewByToggle(bool) {
            this.displayView = bool
        },
        toggleSceneThumbnails() {
            this.showThumbnails = !this.showThumbnails
        },
        hideThumbnails(bool) {
            if (bool == true) {
                this.showThumbnails = false
            }
        },
        setDefaultThumbnails(scene_data) {
            this.scene_thumbnails = []
            scene_data.forEach(scene => {
                var temp_images = []
                scene.scene_image_data.forEach(image => {
                    temp_images.push({
                        image: image.scene_image_url,
                        scene_image_type: image.scene_image_type,
                    })
                })
                this.scene_thumbnails.push(temp_images)
            })
            this.default_scene_image =
                this.scene_thumbnails[this.currentState].length > 0
                    ? this.scene_thumbnails[this.currentState][0].image
                    : ''
        },
        changeDefaultSceneImage(image) {
            this.default_scene_image = image
        },
        addAllRooms(id) {
            this.roomsAvailable = []
            toggleOnlyKitchen(false).then(response => {
                if (response.responseCode == 200) {
                    this.$router.push({ path: `/ehd/${id}` })
                }
            })
        },
        restoreProduct() {
            this.loaderShow = true
            this.showRemovedOverlay = false
            customise_scene({
                action: 'add_to_scene',
                product_id: this.productInfo.product_id,
                scene_id: this.productInfo.scene_id,
                details_id: this.productInfo.specific_id,
                quantity: this.productInfo.quantity,
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.addProduct(response)
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(err => {
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
        },
        loadMoreProducts() {
            const spinner = `<div class="spinner"></div>`
            let loaderContainer = document.querySelector('.loader-container')
            if (
                this.pageNumber < this.totalNumberOfPages &&
                this.autoLoad === true
            ) {
                this.autoLoad = false
                this.pageNumber++
                loaderContainer.innerHTML += spinner
                this.fetchRelatedProducts({
                    obj: `?product_id=${this.productID}${this.filter_str}&page=${this.pageNumber}&size=21&scene_id=${this.currentSceneId}`,
                }).then(response => {
                    if (response.responseCode === 200) {
                        this.totalNumberOfPages = response.payload.total_page
                        loaderContainer.removeChild(
                            document.querySelector('.spinner'),
                        )
                        response.payload.related_product.forEach(product => {
                            this.similarProducts.push(product)
                        })
                        setTimeout(() => {
                            this.autoLoad = true
                        }, 2000)
                    }
                })
            }
        },
        openPDFView() {
            this.$router.push({
                name: 'DownloadBOQ',
                query: { ehd_id: this.$route.params.id },
            })
        },
        updateCurrentState(index) {
            this.currentState != index ? (this.currentTab = 0) : this.currentTab
            this.currentState = index
            this.default_scene_image =
                this.scene_thumbnails[this.currentState].length > 0
                    ? this.scene_thumbnails[this.currentState][0].image
                    : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'
            this.showThumbnails = false
            this.queryString = ''
            this.addProducts = false
            if (this.currentTabTitle === 'Services') {
                if (
                    this.EHDDetails.scene_data[this.currentState].services
                        .length === 0
                ) {
                    this.currentTab = 1
                }
            } else if (this.currentTabTitle === 'Custom Products') {
                if (
                    this.EHDDetails.scene_data[this.currentState]
                        .inactive_one_time_custom_product.length === 0
                ) {
                    this.currentTab = 0
                    this.currentTabTitle = 'Included Products'
                }
            } else if (this.currentTabTitle === 'Included Products') {
                this.currentTab = 0
            } else if (this.currentTabTitle === 'Included Services') {
                this.currentTab = 1
            } else {
                if (
                    !this.EHDDetails.scene_data[this.currentState].products[
                        this.currentTabTitle
                    ]
                ) {
                    this.currentTab = 0
                    this.currentTabTitle = 'Included Products'
                }
            }

            this.updateRelatedProducts()
        },
        updateCurrentTab(index) {
            this.currentTab = index
            if (
                this.currentTabTitle === 'Included Services' ||
                this.currentTabTitle === 'Services' ||
                this.currentTabTitle === 'Custom Products' ||
                this.currentTab === -1
            ) {
                this.displayView = false
            }
            this.queryString = ''
            this.addProducts = false
            this.updateRelatedProducts()
        },
        updateCurrentTabTitle(title) {
            this.currentTabTitle = title
        },
        fetchSuggestions(query) {
            if (query.length > 2) {
                this.suggestions = []
                this.searchNumber = 1
                getProductsBySearch({
                    string: query,
                    page: this.searchNumber,
                }).then(response => {
                    this.suggestions = response.furnish_data
                    this.totalNumberOfSearchPages = response.num_pages
                })
            }
        },
        fetchSearchedProductsOrServices(query) {
            if (query.length > 0) {
                this.currentTab = -1
                this.queryString = query
                this.fetchSearchedProducts({
                    scene: this.EHDDetails.scene_data[this.currentState].id,
                    query: this.queryString,
                }).then(response => {
                    if (
                        response.payload.base_products.length === 0 &&
                        response.payload.products.length === 0
                    ) {
                        this.addProducts = true
                    } else {
                        this.addProducts = false
                    }
                })
            } else {
                this.currentTab = 0
                this.queryString = ''
                this.updateSearchedProducts()
            }
        },
        loadMoreSearchOptions(query) {
            if (
                this.searchNumber < this.totalNumberOfSearchPages &&
                this.searchingActive === true
            ) {
                this.searchingActive = false
                this.searchNumber++
                getProductsBySearch({
                    string: query,
                    page: this.searchNumber,
                }).then(response => {
                    response.furnish_data.forEach(product => {
                        this.suggestions.push(product)
                    })
                    this.searchingActive = true
                    this.totalNumberOfSearchPages = response.num_pages
                })
            }
        },

        toggleReplaceProductModal() {
            this.replaceProductModal = !this.replaceProductModal
            if (this.replaceProductModal) {
                document.querySelector('body').style.overflow = 'hidden'
            } else {
                document.querySelector('body').style.overflow = 'auto'
                this.updateRelatedProducts()
                this.similarProducts = {}
                this.totalNumberOfPages = null
                this.pageNumber = 1
                this.filter_str = ''
                this.suggestions = []
                this.totalNumberOfSearchPages = null
            }
        },

        changeProducts(obj) {
            this.add = false
            this.currentProductShow = true
            this.quantity = obj.total_quantity
            this.currentSceneId = obj.scene_id
            this.ehdId = this.$route.params.id
            this.productID = obj.product.id
            this.productIndex = obj.index
            this.productName = obj.product.name
            this.productDescription = obj.product.description
            this.productPrice = obj.product.item_price
            this.productImage = obj.product.image_url

            // this.fetchScene(obj.scene_id)
            this.fetchFilters({
                source: 'RelatedProduct',
                product_id: this.productID,
            })
            this.fetchRelatedProducts({
                obj: `?product_id=${this.productID}${this.filter_str}&page=${this.pageNumber}&size=21&scene_id=${this.currentSceneId}`,
            }).then(response => {
                this.toggleReplaceProductModal()
                if (response.responseCode === 200) {
                    this.totalNumberOfPages = response.payload.total_page
                    this.similarProducts = response.payload.related_product
                }
                this.loaderShow = false
            })
        },
        showServiceDescription(description) {
            this.showDescription = true
            this.serviceDescription = description
        },
        changeProductForEHD(newproduct) {
            this.loaderShow = true
            this.toggleReplaceProductModal()
            customise_scene({
                action: 'change_product',
                scene_id: this.draftObject.scene_id,
                old_product_id: this.draftObject.product.id,
                new_product_id: newproduct.product_id,
                details_id: this.draftObject.product.specific_data[0].id,
            })
                .then(response => {
                    this.replaceBaseProduct(response)
                    this.loaderShow = false
                })
                .catch(() => {
                    this.loaderShow = false
                })
        },
        clearSearchedProducts() {
            this.queryString = ''
            this.addProducts = false
            this.currentTab = 0
            this.updateRelatedProducts()
        },

        getFilteredProduct(filters) {
            if (filters.vendor_ids.length > 0) {
                let filters_string = Object.keys(filters)
                    .map(key => key + '=' + filters[key])
                    .join('&')
                this.filter_str = `&${filters_string}`
            } else {
                this.filter_str = ''
            }
            this.pageNumber = 1

            this.fetchRelatedProducts({
                obj: `?product_id=${this.productID}${this.filter_str}&page=${this.pageNumber}&size=21&scene_id=${this.currentSceneId}`,
            }).then(response => {
                if (response.responseCode === 200) {
                    this.totalNumberOfPages = response.payload.total_page
                    this.similarProducts = response.payload.related_product
                }
            })
        },

        toggleProducts(obj) {
            this.loaderShow = true
            this.productCategoryName = obj.product.category_group
            document.querySelector('body').style.overflow = 'hidden'
            this.productInfo = {
                product_id: obj.product.id,
                scene_id: obj.scene_id,
                quantity: obj.product.active_quantity,
            }
            customise_scene({
                action: 'disable_all_products',
                product_id: obj.product.id,
                scene_id: obj.scene_id,
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.removeProduct(response)
                        this.showRemovedOverlay = true
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(() => {
                    this.loaderShow = false
                })
        },
        toggleCustomProducts(obj) {
            this.loaderShow = true
            if (obj.enable === false) {
                this.productCategoryName = 'Custom Products'
            } else {
                this.productCategoryName = 'Included Products'
            }
            document.querySelector('body').style.overflow = 'hidden'
            customise_scene({
                action: 'toggle_custom_product',
                product_id: obj.product.prod_id,
                product_enable: obj.enable,
                scene_id: obj.scene_id,
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.toggleCustomProduct(response)
                        this.showRemovedOverlay = true
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(() => {
                    this.loaderShow = false
                })
        },

        changeCount(obj) {
            this.countAltered = false
            this.loaderShow = true
            let object
            document.querySelector('body').style.overflow = 'hidden'
            if (this.countAltered === false) {
                this.countAltered = true
                if (obj.enable === false) {
                    object = {
                        action: 'disable_product',
                        details_id: obj.product.specific_data.filter(
                            value => value.is_part_of_scene === true,
                        )[0].id,
                        scene_id: obj.scene_id,
                    }
                    this.productCategoryName = obj.product.category_group
                    this.productInfo = {
                        product_id: obj.product.id,
                        specific_id: obj.product.specific_data.filter(
                            value => value.is_part_of_scene === true,
                        )[0].id,
                        scene_id: obj.scene_id,
                        quantity: 1,
                    }
                } else {
                    object = {
                        action: 'add_to_scene',
                        product_id: obj.product.id,
                        scene_id: obj.scene_id,
                        quantity: 1,
                    }
                }
                customise_scene(object)
                    .then(response => {
                        // console.log(response, 'change_count')
                        this.loaderShow = false
                        document.querySelector('body').style.overflow = 'auto'

                        if (response.responseCode === 200) {
                            if (
                                response.payload.changes_data.product
                                    .active_quantity > 0
                            ) {
                                this.changeBaseProductCount(response)
                            } else {
                                this.removeProduct(response)
                            }
                            if (obj.enable === false) {
                                this.showRemovedOverlay = true
                            }
                        }
                    })
                    .catch(() => {
                        this.loaderShow = false
                    })
            }
        },

        closeDraftModal() {
            this.loaderShow = false
            this.draftModal = false
            document.querySelector('body').style.overflow = 'auto'
        },

        toggleFullPageModal() {
            this.loaderShow = true
            this.showModal = !this.showModal
            document.querySelector('body').style.overflow = this.showModal
                ? 'hidden'
                : 'auto'
            this.loaderShow = false
        },

        updateEHD(e) {
            this.showRemovedOverlay = false
            this.callDraft = e.callDraft
            this.ehdId = e.ehd_id
            this.currentSceneId = e.scene_id
            if (this.$route.params.id != e.ehd_id) {
                this.$router.push({ path: `/ehd/${e.ehd_id}` })
            } else {
                this.fetchEHDDetailsv3(this.$route.params.id).then(payload => {
                    this.setDefaultThumbnails(payload.data.scene_data)
                    window.scrollTo(0, this.scrollPosition)
                })
                if (e.customisation === null) {
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                }
            }
        },
        addAllProductsToScene(obj) {
            this.showRestoreModal = false
            this.loaderShow = true
            this.productCategoryName = 'Included Products'
            customise_scene({
                action: 'enable_all_products',
                scene_id: obj.scene_id,
                category_group: obj.category,
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.addProduct(response)
                        this.currentTab = 0
                        this.showRemovedOverlay = true
                    }
                    document.querySelector('body').style.overflow = 'auto'
                    this.loaderShow = false
                })
                .catch(() => {
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
        },
        restoreMultipleProducts(obj) {
            this.showRestoreModal = false
            this.productCategoryName = 'Included Products'
            this.loaderShow = true
            customise_scene({
                action: 'add_to_scene',
                product_id: obj.product.id,
                scene_id: obj.scene_id,
                quantity: obj.quantity,
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.addProduct(response)
                        this.showRemovedOverlay = true
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(() => {
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
        },

        checkDraft(obj) {
            this.add = false
            this.loaderShow = true
            this.currentSceneId = obj.scene_id
            this.operation = obj.operation
            this.scene_type = obj.scene_type
            this.draftObject = obj
            this.scrollPosition = window.scrollY
            if (this.checkDraftExists === false) {
                this.checkDraftExists = true
                getDraft(obj.ehd_id, obj.scene_id).then(data => {
                    if (data.responseCode === 2012) {
                        this.checkDraftExists = false
                        switch (obj.operation) {
                            case 'change':
                                this.loaderShow = true
                                this.changeProducts(obj)
                                break
                            case 'add':
                                this.addMoreProducts({
                                    scene_id: obj.scene_id,
                                    scene_type: obj.scene_type,
                                })
                                break
                            case 'toggle':
                                this.toggleProducts(obj)
                                break
                            case 'customProduct':
                                this.toggleCustomProducts(obj)
                                break
                            case 'service':
                                this.toggleServices(obj)
                                break
                            case 'customise':
                                this.customiseScene()
                                break
                            case 'count':
                                this.changeCount(obj)
                                break
                            case 'restore':
                                this.showRestoreModal = true
                                this.loaderShow = false
                                break
                            case 'addAll':
                                this.addAllProductsToScene(obj)
                                break
                            case 'allService':
                                this.toggleAllServices(obj)
                                break
                            default:
                                break
                        }
                    } else {
                        this.useWorkspaceComp = data.data.use_ws_draft_comp
                        this.draftDetails = data.data.draft_info
                        this.draftObject.ehd_id = data.data.ehd_id
                        this.draftObject.scene_id = data.data.scene_id
                        this.showDraftVersions = true
                        document.querySelector('body').style.overflow = 'hidden'
                        this.draftModal = true
                        this.draftModalMsg =
                            "You have already made changes on this EHD. Would you like to 'continue' editing your draft or 'restore' previous changes?"
                        this.draftEHDId = data.data.ehd_id
                        this.draftSceneId = data.data.scene_id
                        this.currentSceneId = this.draftSceneId
                        this.ehdId = data.data.ehd_id
                        this.checkDraftExists = false
                        this.loaderShow = false
                    }
                })
            }
        },
        toggleAllServices(obj) {
            document.querySelector('body').style.overflow = 'hidden'
            customise_scene({
                action: 'toggle_services_by_type',
                service_id: obj.service_id,
                service_type: obj.type,
                service_enable: obj.enable,
                scene_id: obj.scene_id,
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.ehdId = response.payload.ehd_id
                        this.newSceneId = response.payload.scene_id
                        this.toggleService(response)
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(() => {
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
        },

        toggleServices(obj) {
            document.querySelector('body').style.overflow = 'hidden'
            customise_scene({
                action: 'toggle_service',
                service_id: obj.service_id,
                service_enable: obj.enable,
                scene_id: obj.scene_id,
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.ehdId = response.payload.ehd_id
                        this.newSceneId = response.payload.scene_id
                        this.toggleService(response)
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(() => {
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
        },

        // deleteEHDDraft() {
        //     deleteDraft().then(data => {
        //         if (data.responseCode == 200) {
        //             this.callDraft == false
        //             this.draftModal = false
        //             this.draftModalMsg = ''
        //             this.toggleFullPageModal()
        //         }
        //     })
        // },
        customiseScene() {
            if (this.draftObject.scene === 'Kitchen') {
                const that = this
                this.fetchQuoteList(this.$route.params.id).then(response => {
                    if (response.responseCode === 200) {
                        // this.$router.push({name:'KitchenCustomisationPage',query: { eid:obj.ehd_id,sid:obj.scene_id} })
                        let obj_post = {
                            scene_id: this.draftObject.scene_id,
                            ehd_id: this.draftObject.ehd_id,
                            image: this.draftObject.scene_img,
                        }
                        let json_obj_post = JSON.stringify(obj_post)
                        submitKitchenData(json_obj_post).then(response => {
                            if (response.responseCode == 200) {
                                // this.$router.push({
                                //     name: 'EHDPage',
                                //     params: { id: response.payload.ehd_id },
                                // })
                                that.$router.push({
                                    name: 'KitchenCustomisationPage',
                                    query: {
                                        eid: response.payload.ehd_id,
                                        sid: response.payload.scene_id,
                                    },
                                })
                            }
                            this.loaderShow = false
                        })
                        this.loaderShow = false
                    } else {
                        that.toggleFullPageModal()
                        this.loaderShow = false
                    }
                })
            } else {
                this.toggleFullPageModal()
                this.loaderShow = false
            }
        },

        continueWithDraft() {
            this.loaderShow = true
            document.querySelector('body').style.overflow = 'auto'
            if (this.$route.query.cust === 'true' && this.$route.query.scene) {
                this.$router.push({
                    path: `/ehd/${this.draftEHDId}?scene=${this.draftSceneId}&cust=true`,
                })
            } else {
                this.$router.push({ path: `/ehd/${this.draftEHDId}` })
            }
            if (this.draftObject.operation === 'customise') {
                this.customiseScene()
            }

            this.draftModal = false
            this.draftModalMsg = ''
            this.showDraftVersions = false
        },
        restoreDraft() {
            this.loaderShow = true
            document.querySelector('body').style.overflow = 'auto'
            this.restoreDraftDetailsv3({
                ehd_id: this.ehdId,
                scene_id: this.currentSceneId,
            }).then(response => {
                if (response.responseCode === 200) {
                    if (
                        this.$route.query.cust === 'true' &&
                        this.$route.query.scene
                    ) {
                        this.$router.push({
                            path: `/ehd/${this.draftEHDId}?scene=${this.draftSceneId}&cust=true`,
                        })
                    } else {
                        this.$router.push({
                            path: `/ehd/${this.draftEHDId}`,
                        })
                    }

                    if (this.draftObject.operation === 'customise') {
                        this.customiseScene()
                    }
                }
            })

            this.draftModal = false
            this.draftModalMsg = ''
            this.showDraftVersions = false
        },
        activateSeeMore(bool) {
            this.seeMoreActivated = bool
            document.querySelector('.description').style.overflow = this
                .seeMoreActivated
                ? 'auto'
                : 'hidden'
        },

        addMoreProducts(obj) {
            this.add = true
            this.scene_id = obj.scene_id
            this.scene_type = obj.scene_type
            this.add = true
            this.$store.commit('setFiltersData', [])
            this.currentProductShow = false
            // this.fetchFilters({
            //     source: 'RelatedProduct',
            //     product_id: 210,
            // })
            // this.fetchProducts(0)
            this.fetchProducts(0)
                .then(() => {
                    this.loaderShow = false
                })
                .catch(() => {
                    this.loaderShow = false
                })
            this.toggleReplaceProductModal()
        },

        addProductForEHD(newproduct) {
            this.loaderShow = true
            this.toggleReplaceProductModal()
            customise_scene({
                scene_id: this.scene_id,
                product_id: newproduct.product_id,
                action: 'add_product',
            })
                .then(response => {
                    if (response.responseCode === 200) {
                        this.addProduct(response)
                        this.currentTab = 0
                    }
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
                .catch(() => {
                    this.loaderShow = false
                    document.querySelector('body').style.overflow = 'auto'
                })
        },

        loadMore() {
            console.log('loadmore')
        },
    },
}
</script>

<style lang="scss">
@import './EHDPageRefactor.scss';
</style>
