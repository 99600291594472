<template>
    <div class="single-product">
        <div class="best-seller-tag" v-if="product.bestseller">
            <HighlightSVG highlight="bestseller" />
        </div>
        <div
            :class="product.bestseller ? 'recommended-tag' : 'recommended-only'"
            v-if="product.recommended"
        >
            <HighlightSVG highlight="recommended" />
        </div>
        <img
            :src="
                product.image_url
                    ? product.image_url
                    : product.img_url
                    ? product.img_url
                    : require('@/assets/images/default-product-img.png')
            "
            class="product-image"
            :class="product.available === false ? 'unavailable-image' : ''"
        />
        <div class="product-details">
            <router-link
                :to="{
                    name: 'ProductDetailsPage',
                    params: {
                        id: product.id,
                    },
                }"
                target="_blank"
            >
                <p
                    class="product-name"
                    :class="
                        product.available === false ? 'unavailable-text' : ''
                    "
                >
                    {{ product.name.slice(0, 25) }}
                    <span v-if="product.name.length > 25">...</span>
                </p>
            </router-link>
            <p :class="product.available === false ? 'unavailable-text' : ''">
                {{ product.vendor }}
            </p>
        </div>
        <p class="product-rate" v-if="product.rate !== undefined">
            ₹{{ convertToIndianNumberSystem(product.rate) }}
        </p>
        <p class="product-quantity" v-if="product.quantity !== undefined">
            {{ product.quantity }}
        </p>
        <p
            class="product-price"
            :class="product.available === false ? 'unavailable-text' : ''"
        >
            ₹{{
                convertToIndianNumberSystem(
                    product.item_price
                        ? product.item_price
                        : product.price
                        ? product.price
                        : 0,
                )
            }}
        </p>
        <div class="actions-custom" v-if="!product.category_group">
            <div
                v-if="product.active"
                class="action-remove"
                @click="
                    checkDraft({
                        product: product,
                        scene_id: scene_id,
                        operation: 'customProduct',
                        enable: false,
                        ehd_id: ehd_id,
                    })
                "
            >
                <OperationsSVG operation="remove" />
            </div>
            <div
                v-else
                class="action-remove"
                @click="
                    checkDraft({
                        product: product,
                        scene_id: scene_id,
                        operation: 'customProduct',
                        enable: true,
                        ehd_id: ehd_id,
                    })
                "
            >
                + Add
            </div>
        </div>
        <div class="actions" v-else>
            <template v-if="product.active_quantity === 0">
                <div
                    class="add-to-room"
                    v-if="product.available === true"
                    @click="
                        checkDraft({
                            product: product,
                            scene_id: scene_id,
                            operation: 'restore',
                            enable: false,
                            ehd_id: ehd_id,
                        })
                    "
                >
                    + ADD TO ROOM
                </div>
                <div v-else class="product-unavailable">
                    Product Unavailable
                </div>
            </template>
            <template v-else>
                <div
                    class="action-change"
                    @click="
                        checkDraft({
                            product: product,
                            quantity: product.total_quantity,
                            scene_id: scene_id,
                            index: index,
                            operation: 'change',
                            ehd_id: ehd_id,
                        })
                    "
                >
                    CHANGE
                </div>
                <div
                    v-if="product.available === true"
                    class="number-of-products"
                >
                    <div
                        class="remove-one"
                        v-if="product.active_quantity !== 0"
                        @click="
                            checkDraft({
                                product: product,
                                scene_id: scene_id,
                                operation: 'count',
                                enable: false,
                                ehd_id: ehd_id,
                            })
                        "
                    >
                        <OperationsSVG operation="minus" />
                    </div>
                    <div v-else class="disable-icon">
                        <OperationsSVG operation="minus" />
                    </div>

                    <div class="total">
                        {{ product.active_quantity }}
                    </div>

                    <div
                        class="add-one"
                        @click="
                            checkDraft({
                                product: product,
                                scene_id: scene_id,
                                operation: 'count',
                                enable: true,
                                ehd_id: ehd_id,
                            })
                        "
                    >
                        <OperationsSVG operation="plus" />
                    </div>
                </div>
                <div v-else class="product-unavailable">
                    Product Unavailable
                </div>
                <div
                    class="action-remove"
                    @click="
                        checkDraft({
                            product: product,
                            scene_id: scene_id,
                            operation: 'toggle',
                            enable: false,
                            ehd_id: ehd_id,
                        })
                    "
                >
                    <OperationsSVG operation="remove" />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import ConvertToPrice from 'mixins/ConvertToPrice'
import HighlightSVG from 'componentsv3/SVG/HighlightSVG'
import OperationsSVG from 'componentsv3/SVG/OperationsSVG'

export default {
    name: 'ProductBar',
    components: {
        OperationsSVG,
        HighlightSVG,
    },
    mixins: [ConvertToPrice],
    // created(){
    //     console.log(this.product)
    // },
    props: {
        product: {
            type: Object,
            default: () => {},
        },
        index: {
            type: Number,
        },
        scene_id: {
            type: Number,
        },
        ehd_id: {
            type: Number,
        },
        requiredDomain: {
            type: Boolean,
        },
    },
    methods: {
        checkDraft(obj) {
            this.$emit('checkDraft', obj)
        },
    },
}
</script>

<style lang="scss" scoped></style>
