<template>
    <div class="sidedrawer-wrapper" @click="handleClose">
        <transition name="sidedrawer">
            <div class="sidedrawer" @scroll="handleScroll">
                <div class="sidedrawer-header">
                    <div class="close" @click="handleClose">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            fill="none"
                            viewBox="0 0 25 24"
                        >
                            <path
                                fill="#000"
                                d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                            />
                        </svg>
                    </div>
                </div>
                <div class="sidedrawer-body" @click.stop>
                    <slot></slot>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'SideDrawer',
    created() {
        window.addEventListener('scroll', this.handleScroll)
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        handleClose() {
            this.$emit('close')
        },
        handleScroll({ target: { scrollTop } }) {
            let header = document.querySelector('.remove-product-overlay')
            if (scrollTop > 360) {
                header.classList.add('remove-product-overlay-fixed')
            } else {
                header.classList.remove('remove-product-overlay-fixed')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import './SideDrawer.scss';
</style>
