<template>
    <div v-if="listElements.length > 0" :class="{ 'products-grid': obj.view }">
        <div
            v-for="(listElement, indx) in listElements"
            :key="indx"
            :class="{ 'product-in-grid': obj.view }"
        >
            <template v-if="type === 'Products'">
                <template v-if="obj.view">
                    <ProductCard
                        :product="listElement"
                        :index="indx"
                        :scene_id="scene_id"
                        :ehd_id="ehd_id"
                        :requiredDomain="requiredDomain"
                        @checkDraft="checkDraft"
                    />
                </template>
                <template v-else>
                    <ProductBar
                        :product="listElement"
                        :index="indx"
                        :scene_id="scene_id"
                        :ehd_id="ehd_id"
                        :requiredDomain="requiredDomain"
                        @checkDraft="checkDraft"
                    />
                </template>
            </template>
            <template v-if="type === 'Services'">
                <ServiceBar
                    :service="listElement"
                    :active="obj.active"
                    :scene_id="scene_id"
                    :ehd_id="ehd_id"
                    @checkDraft="checkDraft"
                    @showDescirption="showDescirption"
                />
            </template>
        </div>
    </div>
</template>

<script>
import ProductBar from 'componentsv3/ProductBar'
import ProductCard from 'componentsv3/ProductCard'
import ServiceBar from 'componentsv3/ServiceBar'
export default {
    name: 'List',
    props: {
        type: {
            type: String,
            require: true,
        },
        obj: {
            type: Object,
        },
        List: {
            type: Array,
            default: () => {},
        },
        scene_id: {
            type: Number,
        },
        ehd_id: {
            type: Number,
        },
        requiredDomain: {
            type: Boolean,
        },
    },
    computed: {
        listElements() {
            return this.List
        },
    },
    // created() {
    //     console.log('list', this.listElements)
    // },
    components: {
        ProductBar,
        ProductCard,
        ServiceBar,
    },
    methods: {
        checkDraft(obj) {
            this.$emit('checkDraft', obj)
        },
        showDescirption(description) {
            this.$emit('showDescirption', description)
        },
    },
}
</script>

<style lang="scss" scoped></style>
