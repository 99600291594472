var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ehd-page"},[(_vm.isLoggedIn == true)?[(Object.keys(_vm.EHDDetails).length > 0)?[_c('div',{staticClass:"ehd-container"},[_c('div',{staticClass:"ehd-content"},[_c('div',{staticClass:"scene-container"},[_c('div',{staticClass:"scene-body"},[(_vm.windowWidth > 1100)?_c('div',{staticClass:"scene-img-wrapper"},[_c('img',{attrs:{"src":_vm.default_scene_image !== ''
                                            ? _vm.default_scene_image
                                            : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'}}),_c('div',{staticClass:"ehd-scene-button"},[(
                                            _vm.EHDDetails.scene_data[
                                                _vm.currentState
                                            ].vt_link
                                        )?_c('a',{attrs:{"href":_vm.EHDDetails.scene_data[
                                                _vm.currentState
                                            ].vt_link,"target":"_blank"}},[_c('div',{staticClass:"virtual-button"},[_c('span',{staticClass:"button-text"},[_vm._v("Virtual Tour")]),_c('VirtualButtonSVG')],1)]):_vm._e(),(
                                            _vm.scene_thumbnails[_vm.currentState]
                                                .length > 1
                                        )?[(!_vm.showThumbnails)?_c('div',{staticClass:"image-button",attrs:{"tabindex":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSceneThumbnails($event)},"focus":function($event){return _vm.hideThumbnails(false)},"blur":function($event){return _vm.hideThumbnails(true)}}},[_c('span',{staticClass:"button-text"},[_vm._v("More Image")]),_c('EhdMoreImageButtonSVG')],1):_c('div',{staticClass:"nocursor-view",attrs:{"tabindex":"0"},on:{"focus":function($event){return _vm.hideThumbnails(false)},"blur":function($event){return _vm.hideThumbnails(true)}}},[_c('span',{staticClass:"more-text"},[_vm._v("More Image")]),_c('EhdMoreImageButtonSVG'),_c('div',{staticClass:"dropdown_menu"},[_c('div',{staticClass:"scrollable-menu"},_vm._l((_vm.scene_thumbnails[
                                                            _vm.currentState
                                                        ]),function(image,i){return _c('div',{key:i,staticClass:"thumbnail-container",on:{"click":function($event){return _vm.changeDefaultSceneImage(
                                                                image.image
                                                            )}}},[_c('div',{staticClass:"thumbnail-image"},[_c('img',{class:_vm.default_scene_image ==
                                                                    image.image
                                                                        ? 'activeThumbnail'
                                                                        : '',attrs:{"src":image.image}})]),_c('p',[_vm._v(" "+_vm._s(image.scene_image_type)+" ")])])}),0),_c('CaretSVG',{attrs:{"direction":"reverse"}})],1)],1)]:_vm._e()],2),_c('button',{staticClass:"ehd-full-screen",attrs:{"href":_vm.default_scene_image !== ''
                                            ? _vm.default_scene_image
                                            : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg',"target":"_blank"},on:{"click":function($event){return _vm.showFullEhdModal(true)}}},[_c('EhdFullScreenSVG')],1)]):_vm._e()])]),_c('Tabs',{key:_vm.EHDDetails.id,staticClass:"scene-tabs",attrs:{"isselected":_vm.currentState,"ehd":true,"carousel":true},on:{"selectedTab":_vm.updateCurrentState}},[(_vm.showRemovedOverlay)?_c('WarningBanner',{attrs:{"productCategoryName":_vm.productCategoryName},on:{"restoreProduct":_vm.restoreProduct}}):_vm._e(),_vm._l((_vm.EHDDetails.scene_data),function(scene,index){return _c('Tab',{key:((scene.id) + "_" + index),attrs:{"title":scene.room_type,"img":scene.scene_image_data.length > 0
                                    ? scene.scene_image_data[0]
                                          .scene_image_url
                                    : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg',"blur":!_vm.roomsAvailable[index].room_available}},[_c('div',{staticClass:"scene-details"},[_c('div',{staticClass:"search-and-view"},[_c('SearchAutoComplete',{staticClass:"search-complete",on:{"search":_vm.fetchSearchedProductsOrServices,"clear":function($event){return _vm.clearSearchedProducts()}}}),_c('div',{class:_vm.currentTabTitle !=
                                                'Included Services' &&
                                            _vm.currentTabTitle !=
                                                'Custom Products' &&
                                            _vm.currentTabTitle != 'Services' &&
                                            _vm.currentTab != -1
                                                ? 'viewby-category'
                                                : 'viewby-single'},[_c('div',{staticClass:"category-list",on:{"click":function($event){return _vm.displayViewByToggle(false)}}},[_c('CategoryListViewSVG',{attrs:{"fill":_vm.displayView
                                                        ? '#bbbbbb'
                                                        : '#ff6100'}})],1),(
                                                _vm.currentTabTitle !=
                                                    'Included Services' &&
                                                    _vm.currentTabTitle !=
                                                        'Services' &&
                                                    _vm.currentTab != -1 &&
                                                    _vm.currentTabTitle !=
                                                        'Custom Products'
                                            )?_c('div',{staticClass:"category-thumb",on:{"click":function($event){return _vm.displayViewByToggle(true)}}},[_c('CategoryListViewSVG',{attrs:{"view":"thumb","customClass":_vm.displayView
                                                        ? 'cls-1'
                                                        : '',"fill":_vm.displayView
                                                        ? '#ff6100'
                                                        : '#bbbbbb'}})],1):_vm._e()])],1),_c('Tabs',{staticClass:"ehd-tab-data",attrs:{"isselected":_vm.currentTab,"verticalTabs":true},on:{"selectedTab":_vm.updateCurrentTab,"seletedTabTitle":_vm.updateCurrentTabTitle}},[(
                                            _vm.queryString !== '' &&
                                                _vm.searchedProducts.responseCode ===
                                                    200
                                        )?_c('div',{staticClass:"products-active",staticStyle:{"width":"100%"}},[(_vm.addProducts === true)?[_c('div',{staticClass:"add-more-container"},[_c('p',{staticClass:"add-product-header"},[_vm._v(" No Product Found ")])])]:[_c('List',{attrs:{"type":"products","obj":{ view: false },"List":_vm.searchedProducts.payload
                                                        .base_products
                                                        .length > 0
                                                        ? _vm.searchedProducts
                                                              .payload
                                                              .base_products
                                                        : _vm.searchedProducts
                                                              .payload
                                                              .products
                                                              .length > 0
                                                        ? _vm.searchedProducts
                                                              .payload
                                                              .products
                                                        : [],"scene_id":scene.id,"ehd_id":_vm.EHDDetails.id,"requiredDomain":_vm.requiredDomain},on:{"checkDraft":_vm.checkDraft}})],_c('p',{staticClass:"add-prod-button",on:{"click":function($event){return _vm.checkDraft({
                                                    ehd_id: _vm.EHDDetails.id,
                                                    scene_id: scene.id,
                                                    operation: 'add',
                                                    scene_type:
                                                        scene.scene_type,
                                                })}}},[_vm._v(" + Add New Product ")])],2):_vm._e(),_c('Tab',{attrs:{"title":"Included Products","verticalTab":true}},[(
                                                _vm.roomData[_vm.currentState]
                                                    .products.length > 0 ||
                                                    _vm.roomData[_vm.currentState]
                                                        .customProducts
                                                        .length > 0
                                            )?_c('div',{staticClass:"products-active"},[_c('List',{attrs:{"type":"Products","obj":{
                                                    view: _vm.displayView,
                                                },"List":_vm.roomData[
                                                        _vm.currentState
                                                    ].products.concat(
                                                        _vm.roomData[
                                                            _vm.currentState
                                                        ].customProducts
                                                    ),"scene_id":scene.id,"ehd_id":_vm.EHDDetails.id,"requiredDomain":_vm.requiredDomain},on:{"checkDraft":_vm.checkDraft}})],1):_c('div',{staticClass:"no_active_product"},[_c('p',{staticClass:"no_active_product_content"},[_vm._v(" No active products ")])])]),_c('Tab',{attrs:{"title":"Included Services","verticalTab":true}},[(
                                                _vm.roomData[_vm.currentState]
                                                    .services.length > 0
                                            )?_c('div',{staticClass:"services-active"},[_c('List',{attrs:{"type":"Services","obj":{
                                                    active: true,
                                                },"List":_vm.roomData[_vm.currentState]
                                                        .services,"scene_id":scene.id,"ehd_id":_vm.EHDDetails.id,"requiredDomain":_vm.requiredDomain},on:{"checkDraft":_vm.checkDraft}})],1):_c('div',{staticClass:"no_active_product"},[_c('p',{staticClass:"no_active_product_content"},[_vm._v(" No active services available. ")])])]),_vm._l((_vm.roomData[
                                            _vm.currentState
                                        ].categoryGroups),function(data,index){return _c('Tab',{key:index,attrs:{"title":data,"verticalTab":true}},[_c('div',{staticClass:"products-active"},[_c('p',{staticClass:"add-all-products",on:{"click":function($event){return _vm.checkDraft({
                                                        product:
                                                            _vm.removedProduct[
                                                                data
                                                            ][0],
                                                        scene_id: scene.id,
                                                        operation: 'addAll',
                                                        enable: true,
                                                        ehd_id:
                                                            _vm.EHDDetails.id,
                                                        category: data,
                                                    })}}},[_vm._v(" + Add all "+_vm._s(data)+" Products ")]),_c('List',{attrs:{"type":"Products","obj":{
                                                    view: _vm.displayView,
                                                },"List":_vm.removedProduct[data],"scene_id":scene.id,"ehd_id":_vm.EHDDetails.id,"requiredDomain":_vm.requiredDomain},on:{"checkDraft":_vm.checkDraft}})],1)])}),(
                                            _vm.roomData[_vm.currentState]
                                                .removedServices.length > 0
                                        )?_c('Tab',{attrs:{"title":"Services","verticalTab":true}},[_c('div',{staticClass:"services-active"},[_c('List',{attrs:{"type":"Services","obj":{
                                                    active: false,
                                                },"List":_vm.roomData[_vm.currentState]
                                                        .removedServices,"scene_id":scene.id,"ehd_id":_vm.EHDDetails.id,"requiredDomain":_vm.requiredDomain},on:{"checkDraft":_vm.checkDraft}})],1)]):_vm._e(),(
                                            _vm.roomData[_vm.currentState]
                                                .removedCustomProducts
                                                .length > 0
                                        )?_c('Tab',{staticClass:"services-active",attrs:{"title":"Custom Products","verticalTab":true}},[_c('div',{staticClass:"products-active"},[_c('List',{attrs:{"type":"Products","obj":{
                                                    view: false,
                                                },"List":_vm.roomData[_vm.currentState]
                                                        .removedCustomProducts,"scene_id":scene.id,"ehd_id":_vm.EHDDetails.id,"requiredDomain":_vm.requiredDomain},on:{"checkDraft":_vm.checkDraft}})],1)]):_vm._e()],2)],1)])})],2)],1),_c('div',{staticClass:"ehd-details"},[_c('div',{staticClass:"finalise-div"},[_c('p',[_vm._v(" Total Cost: "),(_vm.$route.query.ok)?_c('span',[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( _vm.kitchenPrice ))+" ")]):_c('span',[_vm._v(" ₹ "+_vm._s(_vm.convertToIndianNumberSystem( _vm.EHDDetails.total_cost ))+" ")])]),_c('p',[_vm._v("Estimated Completion 4 weeks")]),_c('router-link',{attrs:{"to":{
                                name: 'FinaliseAndPlaceOrderPage',
                                query: {
                                    ehd_id: _vm.EHDDetails.id,
                                    scene_id: _vm.EHDDetails.scene_data[0].id,
                                },
                            }}},[_c('Button',{staticClass:"finalise-button",attrs:{"name":"Finalize","primary":""}})],1)],1),_c('img',{staticClass:"designer-image",attrs:{"src":_vm.EHDDetails.designer_image
                                ? _vm.EHDDetails.designer_image
                                : require('assets/images/default-designer.png'),"alt":_vm.EHDDetails.designer}}),_c('p',{staticClass:"detail-text"},[_vm._v(" Designed by "+_vm._s(_vm.EHDDetails.designer)+" ")]),_c('p',{staticClass:"detail-text"},[_vm._v(" Project Name: "+_vm._s(_vm.EHDDetails.project_name)+" ")]),_c('p',{staticClass:"detail-text"},[_vm._v(" Layout Type: "+_vm._s(_vm.EHDDetails.floor_plan)),(_vm.EHDDetails.carpet_area)?_c('span',[_vm._v("("+_vm._s(_vm.EHDDetails.carpet_area)+")")]):_vm._e()]),_c('p',{staticClass:"detail-text"},[_vm._v(" "+_vm._s(_vm.EHDDetails.scene_data[_vm.currentState].room_type)+" | ₹"+_vm._s(_vm.convertToIndianNumberSystem( _vm.EHDDetails.scene_data[_vm.currentState].price ))+" | "+_vm._s(_vm.EHDDetails.theme)+" ")]),_c('div',{staticClass:"total-price"},[_c('p',[_vm._v(" Included Products("+_vm._s(_vm.EHDDetails.scene_data[_vm.currentState] .base_products.length)+")"),_c('span',[_vm._v("₹"+_vm._s(_vm.convertToIndianNumberSystem( _vm.EHDDetails.scene_data[_vm.currentState] .price_of_products )))])]),_c('p',[_vm._v(" Included Services ("+_vm._s(_vm.EHDDetails.scene_data[_vm.currentState] .base_services.length)+")"),_c('span',[_vm._v("₹"+_vm._s(_vm.convertToIndianNumberSystem( _vm.EHDDetails.scene_data[_vm.currentState] .price_of_services )))])]),_c('p',{staticClass:"total-cost"},[_vm._v(" Total Cost"),_c('span',[_vm._v("₹"+_vm._s(_vm.convertToIndianNumberSystem( _vm.EHDDetails.scene_data[_vm.currentState] .price )))])])]),(
                            _vm.EHDDetails.scene_data[_vm.currentState]
                                .scene_type == 'WHITE'
                        )?_c('Button',{staticClass:"customisation-button",attrs:{"name":"customise","orange":"","data-scene-id":_vm.EHDDetails.scene_data[_vm.currentState].id},nativeOn:{"click":function($event){return _vm.checkDraft({
                                ehd_id: _vm.EHDDetails.id,
                                scene_id:
                                    _vm.EHDDetails.scene_data[_vm.currentState].id,
                                operation: 'customise',
                                scene:
                                    _vm.EHDDetails.scene_data[_vm.currentState]
                                        .room_type,
                                scene_img:
                                    _vm.EHDDetails.scene_data[_vm.currentState]
                                        .scene_image_data[0].length != 0
                                        ? _vm.EHDDetails.scene_data[
                                              _vm.currentState
                                          ].scene_image_data[0]
                                              .scene_image_url
                                        : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg',
                            })}}}):_vm._e()],1)]),(_vm.showDescription)?_c('Modal',{attrs:{"transparent":""},on:{"close":function($event){_vm.showDescription = false}}},[_c('div',{staticClass:"service-description"},[_c('p',[_vm._v("Description")]),_vm._v(" "+_vm._s(_vm.serviceDescription)+" ")])]):_vm._e(),(_vm.showRestoreModal)?_c('Modal',{attrs:{"transparent":""},on:{"close":function($event){;(_vm.showRestoreModal = false), (_vm.countOfProduct = 1)}}},[_c('div',{staticClass:"removed-products-popup"},[_c('p',[_vm._v(" "+_vm._s(_vm.draftObject.product.name.slice(0, 50))),(_vm.draftObject.product.name.length > 50)?_c('span',[_vm._v("...")]):_vm._e()]),_c('div',{staticClass:"number-of-products"},[(_vm.countOfProduct != 1)?_c('div',{staticClass:"remove-one",on:{"click":function($event){_vm.countOfProduct--}}},[_c('OperationsSVG',{attrs:{"operation":"minus"}})],1):_c('div',{staticClass:"disable-icon"},[_c('OperationsSVG',{attrs:{"operation":"minus"}})],1),_c('div',{staticClass:"total"},[_vm._v(" "+_vm._s(_vm.countOfProduct)+" ")]),_c('div',{staticClass:"add-one",on:{"click":function($event){_vm.countOfProduct++}}},[_c('OperationsSVG',{attrs:{"operation":"plus"}})],1)]),_c('div',{staticClass:"add-button",on:{"click":function($event){return _vm.restoreMultipleProducts({
                                product: _vm.draftObject.product,
                                scene_id: _vm.draftObject.scene_id,
                                operation: 'addMultiple',
                                enable: true,
                                ehd_id: _vm.draftObject.ehd_id,
                                quantity: _vm.countOfProduct,
                            })}}},[_vm._v(" + ADD ")])])]):_vm._e()]:_vm._e(),(_vm.showNotFound == true)?[_c('NotFound',{attrs:{"data":'EHD',"content":_vm.content}})]:_vm._e(),(_vm.showModal)?_c('FullPageNavModal',{attrs:{"sceneId":_vm.currentSceneId,"ehdId":_vm.ehdId,"theme":_vm.EHDDetails.theme,"requiredDomain":_vm.requiredDomain},on:{"close":_vm.toggleFullPageModal,"update":_vm.updateEHD}}):_vm._e(),(_vm.replaceProductModal == true)?_c('FullPageModal',{staticClass:"replace-product-modal",attrs:{"replaceProduct":true},on:{"close":_vm.toggleReplaceProductModal,"loadMoreProducts":_vm.loadMoreProducts}},[_c('div',{staticClass:"productlist-layout"},[_c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"sidebar-item"},[(_vm.currentProductShow == true)?_c('div',{staticClass:"current-product"},[_c('router-link',{attrs:{"to":{
                                    name: 'ProductDetailsPage',
                                    params: {
                                        id: _vm.productID,
                                    },
                                }}},[_c('CurrentProduct',{attrs:{"img":_vm.productImage
                                            ? _vm.productImage
                                            : require('@/assets/images/default-product-img.png'),"productName":_vm.productName,"requiredDomain":_vm.requiredDomain,"productPrice":_vm.productPrice,"description":_vm.productDescription}})],1)],1):_vm._e(),(
                                _vm.add == false &&
                                    _vm.filters[1].options.length != 0
                            )?_c('div',{staticClass:"filters"},[(_vm.windowWidth > 1100)?_c('FiltersBox',{attrs:{"filters":_vm.filters},on:{"filter":_vm.getFilteredProduct}}):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"main"},[(_vm.relatedProducts)?[_c('div',{staticClass:"productlist-head"},[_c('h1',{},[_vm._v(" "+_vm._s(_vm.add == true ? 'Add product' : 'Replace with')+" ")]),_c('SearchAutoComplete',{attrs:{"suggestionsArr":_vm.suggestions},on:{"search":_vm.fetchSuggestions,"loadMoreSearchOptions":_vm.loadMoreSearchOptions},scopedSlots:_vm._u([{key:"suggestion",fn:function(ref){
                            var suggestion = ref.suggestion;
return [_c('div',{staticClass:"autocomplete-product"},[_c('div',{staticClass:"autocomplete-product-img"},[_c('img',{attrs:{"src":suggestion.image}})]),_c('div',{staticClass:"autocomplete-product-detail"},[_c('p',[_vm._v(_vm._s(suggestion.name))]),_c('div',{staticClass:"autocomplete-category"},[_c('span',[_vm._v(" "+_vm._s(suggestion.item_category)+" ")]),(
                                                        suggestion.item_subcategory
                                                    )?[_c('span',[_c('CaretSVG',{attrs:{"direction":"right"}})],1),_c('span',[_vm._v(" "+_vm._s(suggestion.item_subcategory)+" ")])]:_vm._e()],2)]),_c('div',{staticClass:"autocomplete-product-action"},[(_vm.add == true)?[_c('Button',{staticClass:"autocomplete-add",attrs:{"name":"Add","primary":""},nativeOn:{"click":function($event){return _vm.addProductForEHD(
                                                            suggestion
                                                        )}}})]:[_c('Button',{staticClass:"autocomplete-add",attrs:{"name":"Replace","primary":""},nativeOn:{"click":function($event){return _vm.changeProductForEHD(
                                                            suggestion
                                                        )}}})]],2)])]}}],null,false,2800223160)})],1),_c('div',{staticClass:"cards-container"},[(_vm.relatedProducts.payload.prod_data)?_vm._l((_vm.relatedProducts
                                        .payload.prod_data),function(product){return _c('div',{key:product.id},[(_vm.add === true)?_c('ProductCards',{attrs:{"threeDavailable":product.is_3d_available,"product-obj":product,"vendor":product.vendor,"dimRemarks":product.dim_remarks,"product-id":product.product_id,"img":product.image_meta
                                                ? product.image_meta.n_image
                                                : require('@/assets/images/default-product-img.png'),"name":product.name,"current-price":product.item_price,"requiredDomain":_vm.requiredDomain,"bestSeller":product.bestseller,"recommended":product.recommended},on:{"add":function($event){return _vm.addProductForEHD(product)}}}):_vm._e()],1)}):_vm._l((_vm.similarProducts),function(product){return _c('div',{key:product.id},[_c('ProductCards',{attrs:{"threeDavailable":product.is_3d_available,"product-obj":product,"replace":"","vendor":product.vendor,"dimRemarks":product.dim_remarks,"product-id":product.product_id,"img":product.image_meta
                                                ? product.image_meta.n_image
                                                : require('@/assets/images/default-product-img.png'),"name":product.name,"current-price":product.item_price,"requiredDomain":_vm.requiredDomain,"bestSeller":product.bestseller,"recommended":product.recommended},on:{"replace":function($event){return _vm.changeProductForEHD(product)}}})],1)})],2),_c('div',{staticClass:"loader-container"})]:[_c('div',{staticClass:"no-products-found",staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":require("@/assets/images/no-results-found.png")}}),_c('p',[_vm._v("No Products Available")])])]],2)]),_c('div',{staticClass:"request-product-container"},[_c('RequestProduct')],1)]):_vm._e(),(_vm.draftModal)?_c('Modal',{attrs:{"transparent":""},on:{"close":_vm.closeDraftModal}},[_c('div',{staticClass:"ehdmodal"},[_c('div',{staticClass:"ehdmodal-view"},[_c('p',[_vm._v(_vm._s(_vm.draftModalMsg))]),(_vm.showDraftVersions == true)?_c('div',{staticClass:"ehd-display"},[(_vm.useWorkspaceComp == false)?[_c('div',{staticClass:"draft-ehd"},[_c('p',[_vm._v("My Draft")]),_c('div',{staticClass:"draft-details"},[_c('img',{attrs:{"src":_vm.draftDetails.draft_scene
                                                .scene_image}}),_c('div',{staticClass:"draft-info"},[_c('p',{class:_vm.requiredDomain == false
                                                    ? 'blur'
                                                    : ''},[_vm._v(" ₹"+_vm._s(_vm.convertToIndianNumberSystem( _vm.draftDetails.draft_scene .price ))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.draftDetails.draft_scene .no_of_products)+" Products ")]),_c('p',[_vm._v(" "+_vm._s(_vm.draftDetails.draft_scene .no_of_services)+" Services ")])])])]),_c('div',{staticClass:"original-ehd"},[_c('p',[_vm._v("Original")]),_c('div',{staticClass:"draft-details"},[_c('img',{attrs:{"src":_vm.draftDetails.original_scene
                                                .scene_image}}),_c('div',{staticClass:"draft-info"},[_c('p',{class:_vm.requiredDomain == false
                                                    ? 'blur'
                                                    : ''},[_vm._v(" ₹"+_vm._s(_vm.convertToIndianNumberSystem( _vm.draftDetails .original_scene .price ))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.draftDetails.original_scene .no_of_products)+" Products ")]),_c('p',[_vm._v(" "+_vm._s(_vm.draftDetails.original_scene .no_of_services)+" Services ")])])])])]:[_c('div',[_c('WorkspaceComponent',{attrs:{"draftImage":_vm.draftDetails.draft_scene.scene_image,"theme":_vm.draftDetails.draft_scene.theme_name,"ogDetails":_vm.draftDetails.original_scene,"price":_vm.draftDetails.draft_scene.price,"footerName":_vm.draftDetails.draft_scene
                                            .display_name,"noOfProducts":_vm.draftDetails.draft_scene
                                            .no_of_products,"noOfServices":_vm.draftDetails.draft_scene
                                            .no_of_services,"space":_vm.draftDetails.draft_scene.space_name,"eta":_vm.draftDetails.draft_scene.ETA,"products":_vm.draftDetails.draft_scene
                                            .no_of_products,"timeCreated":_vm.draftDetails.draft_scene.updated_at,"sceneid":_vm.draftDetails.draft_scene.scene_id,"ehdid":_vm.draftDetails.draft_scene
                                            .draft_ehd_id
                                            ? _vm.draftDetails.draft_scene
                                                  .draft_ehd_id
                                            : null,"draftBySpace":true,"hideButtons":true}})],1)]],2):_vm._e(),(_vm.showDraftVersions == true)?_c('div',{staticClass:"result-button",staticStyle:{"margin-top":"20px","display":"flex","justify-content":"center"}},[_c('Button',{staticClass:"delete-draft",attrs:{"name":"Continue","outline":""},nativeOn:{"click":function($event){return _vm.continueWithDraft($event)}}}),_c('Button',{staticClass:"override",staticStyle:{"margin-left":"20px"},attrs:{"name":"Restore","primary":""},nativeOn:{"click":function($event){return _vm.restoreDraft($event)}}})],1):_vm._e()])])]):_vm._e(),(_vm.showEhdModal)?_c('Modal',{attrs:{"customClass":"scene-image-modal","transparent":""},on:{"close":_vm.showFullEhdModal}},[_c('img',{staticClass:"fimage",attrs:{"src":_vm.default_scene_image !== ''
                        ? _vm.default_scene_image
                        : 'https://rift-uat.s3.amazonaws.com/photos/cache/92/5e/925e614b20b09631b3f61bf9cf9e1023.jpg'}})]):_vm._e(),(
                _vm.loaderShow ||
                    (Object.keys(_vm.EHDDetails).length == 0 && !_vm.showNotFound)
            )?[_c('div',{staticClass:"wait"},[_c('Loader',{attrs:{"loaderType":_vm.loaderType}})],1)]:_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }